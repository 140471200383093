import React from "react"
import setAddress from '../utils/MetamaskInject'

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            add1: "",
            //   adminInfoFromApi: ""
        }


        setAddress().then(add2 => {
            this.setState({add1: add2})
            console.log("add2::", add2)

        })
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow">
                    <div className="container">
                        <a className="navbar-brand" href="./Home">
                            <img src="./assets/Images/HomeScreen/Reagent_logo.png" className="img-fluid reagent-logo"
                                 alt="logo"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto align-items-center">
                                <li className="nav-item active mr-2">
                                    <a className="nav-link text-uppercase cool-link" href="/marketPlace:pixelbart" >Marketplace</a>
                                </li>
                                <li className="nav-item mr-2">
                                    <a className="nav-link text-uppercase cool-link" href="./featuredView">My Art</a>
                                </li>
                                <li className="nav-item mr-2">
                                    <a className="nav-link text-uppercase cool-link" href="./physicalStore">PhysicalStore</a>
                                </li>
                                
                                <li className="nav-item mr-2">
                                    <a className="nav-link text-uppercase cool-link"
                                       href="./term&Condition">Help &amp; FAQs</a>
                                </li>
                                <li className="nav-item mr-2">
                                    <h3>{this.state.add1 ? "Connected" : "Not Connected"}</h3>
                                    {/* <a className="nav-link text-uppercase cool-link" href="/accountSetting">My Account</a> */}
                                </li>
                                <li className={'LanguageDropDown'}>
                                   <p className={'mb-1'}><a className={'d-block px-3 text-uppercase '} href={'javascript:void(0);'}>English</a></p>
                                   <p className={'mb-0'}><a className={'d-block px-3 text-uppercase '} href={'javascript:void(0);'}>中國人</a></p>
                                </li>
                                {/*<li className="nav-item dropdown border rounded">
                                    <a className="nav-link dropdown-toggle" href="javascript:void(0)"
                                       id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <img src="./assets/Images/HomeScreen/united-states.png" className="img-fluid"
                                             alt="UE"/>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="javascript:void(0)">
                                            <img className="img-fluid"
                                                 src="./assets/Images/HomeScreen/united-states.png" alt="UE"/> CH
                                        </a>
                                        <a className="dropdown-item" href="javascript:void(0)"><img
                                            className="img-fluid" src="./assets/Images/HomeScreen/china.png"
                                            alt="UE"/> EN</a>
                                    </div>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

export default Navigation
