import React from "react"
import {connect} from 'react-redux';

import setAddress from '../../utils/MetamaskInject'
import {getSpecificCollection} from '../../services/collection/action'

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            add1: "",
            //   adminInfoFromApi: ""
        }

        this.copyUrl = this.copyUrl.bind(this)
        this.checkMetaMask = this.checkMetaMask.bind(this)

        setAddress().then(add2 => {
                this.setState({add1: add2})
                console.log("add2::", add2)

            }
        )

        this.props.getSpecificCollection('emperorofchina')

    }

    copyUrl() {
        /* Get the text field */
        var copyText = document.getElementById("copyValue");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        alert("Copied the text: " + copyText.value);


        this.props.history.push("/Home")
    }


    async checkMetaMask() {

        if (this.state.add1 != "") {
            this.props.history.push("/Home")
        } else {
            alert("First connect Metamask!")

            //   this.props.history.push("/login")
        }
//        await setAddress().then( i=>{

//           console.log("checkMetaMask",i)
//           alert(i)
//           if(i!=''){
//  // alert(i)
//               console.log("checkMetaMask",i)
//              // this.props.history.push("/dashboard",{ address:add })
//               this.props.history.push("/marketPlace")
//           }
//           else{
//               alert("First connect Metamask!")
//               alert("First connect Metamask!")
//            //   this.props.history.push("/login")
//           }


//         })


    }


    render() {
        return (
            <div>
                {/* SignUP Section */}
                <div className="Signup-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 a-side">
                                <img src="./assets/Images/Rewards/Group 1.png" className="img-fluid reagent-logo"
                                     alt="logo"/>
                                <h1> Register As Creator </h1>
                                <p>Register as creator with us and sell your NFTs on our platform exclusively. </p>
                                <h4>How we work?</h4>
                                <p>
                                    We generally take 12% commission and for the artist to apply they have to upload 4
                                    pieces
                                    of work that is ready for us to mint with title, links to their instagram or
                                    website</p>
                                <button type="button" onClick={() => this.props.history.push("/signup")}
                                        className="mt-4">
                                    Register
                                </button>
                                <div className="copyright">Copyrights @ 2021 Reagent, All rights reserved.</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 b-side">
                                <div className={'dropdown LanguageDropDown'}>
                                    <p className={'mb-1'}><a className={'d-block px-3 text-uppercase '} href={'javascript:void(0);'}>English</a></p>
                                    <p className={'mb-0'}><a className={'d-block px-3 text-uppercase '} href={'javascript:void(0);'}>中國人</a></p>
                                </div>
                                {/*<div className="dropdown border rounded">
                                    <a className="dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="./assets/Images/HomeScreen/united-states.png" className="img-fluid"
                                             alt="UE"/>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="javascript:void(0)">
                                            <img className="img-fluid"
                                                 src="./Assets/Images/HomeScreen/united-states.png" alt="UE"/> CH
                                        </a>
                                        <a className="dropdown-item" href="javascript:void(0)"><img
                                            className="img-fluid" src="./Assets/Images/HomeScreen/china.png"
                                            alt="UE"/> EN</a>
                                    </div>
                                </div>*/}
                                <div>
                                    <img src="./assets/Images/Modals/metamask-fox.png" className="img-fluid"/>
                                    <h4>Login With Metamask</h4>
                                    <p>Authenticate via Metamask to get access to the system!</p>
                                </div>
                                <div className="input-group url-copy mb-4">
                                    <input type="text" id="copyValue"
                                           placeholder={this.state.add1 ? this.state.add1 : "Connect Wallet"}
                                           className="form-control" readOnly/>
                                </div>


                                <a href="#" onClick={this.checkMetaMask}>
                                    <div className="url-copy justify-content-center">

                                        {/* <div onClick={ this.checkMetaMask} > */}
                                        <button type="button" id="copyValue" className={'btn mt-0 BtnBorder rounded px-5'}>Login</button>

                                        {/* <i className="far fa-clone"
                            // onClick={() => this.copyUrl()}  
                            /> */}
                                        {/* </div> */}

                                    </div>
                                </a>
                                {/* <a href="#">
                        <button  className="input-group-append"  type="button" onClick={ this.checkMetaMask}  className="mt-4">
                        Login
                      </button>
                      </a> */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* SignUP Section End*/}
                {/* JQuery Start */}
                {/*Plugin CSS file with desired skin*/}
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.1/css/ion.rangeSlider.min.css"/>
                {/*Plugin JavaScript file*/}
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    // eth_history: state.historyStore.eth_history,
    collection_data: state.collectionStore.collection_data,
});


export default connect(mapStateToProps, {
    getSpecificCollection
})(
    Login
);
// export default Login
    