import React from "react"

class AccountSettingHeader extends React.Component {
  
	render() {
		return (
			<>


<section className="Marketplace-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
            <h2> Account Setting </h2>
          <p>All Your Profile and Account Settings </p>
            </div>
          </div>
        </div>
      </section>
      	</>
		)
	}
}

export default AccountSettingHeader
