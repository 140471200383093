import React from "react"
import axios from "axios";
import info from '../../utils/emailKey'
import emailjs from 'emailjs-com';
import "react-toastify/dist/ReactToastify.min.css";
import { Flip, toast } from "react-toastify";

class Signup extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      username: "",
      email:"",
      location:"",
       result: "",

      message: "",
    };
    
    
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
   
}

onChange(e) {
  this.setState({
    [e.target.name]: e.target.value,
  });
}

onSubmit = async (e) => {
  e.preventDefault();


if (this.state.username === "") {
  toast.configure();
  toast("User name Required!", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    toastId: 1
  });
}
else if (this.state.email === "") {
  toast.configure();
  toast("email Required!", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    toastId: 2,
  });
}

else if (this.state.location === "") {
  toast.configure();
  toast("location Required!", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    toastId: 3,
  });
}

else if (this.state.message === "") {
  toast.configure();
  toast("message Required!", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    toastId: 4,
  });
}
else{

  var template_params= {
    'firstname': this.state.username,
    'email': this.state.email,
    'location': this.state.location,
    'message': this.state.message
}


emailjs.send(info.SERVICE_ID(),info.TEMPLATE_ID(), template_params, info.USER_ID())
.then((response) => {
   console.log('SUCCESS!', response.status, response.text);
   toast.configure();
     toast("Email Sent Successfuly", {
       position: toast.POSITION.TOP_RIGHT,
       autoClose: 2000,
       toastId: 5,
     });
}, (err) => {
   console.log('FAILED...', err);
});

}
};

	render() {
		return (

          
          <div>
        {/* SignUP Section */}
        <div className="Signup-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 a-side">
                <img src="./assets/Images/Rewards/Group 1.png" className="img-fluid reagent-logo" alt="logo" />
                <h1> Register As Creator </h1>
                <p>Register as creator with us and sell your NFTs on our platform exclusively. </p>
                <h4>How we work?</h4>
                <p>
                  We generally take 12% commission and for the artist to apply they have to upload 4 pieces
                  of work that is ready for us to mint with title, links to their instagram or website</p>
                <button type="button" href="./term&Condition" className="mt-4">
                  Check Our FAQs Section
                </button>
                <div className="copyright">Copyrights @ 2021 Reagent, All rights reserved.</div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 b-side B-side">
                <div className="dropdown border rounded">
                  <a className="dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="./assets/Images/HomeScreen/united-states.png" className="img-fluid" alt="UE" />
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a className="dropdown-item" href="javascript:void(0)">
                      <img className="img-fluid" src="./Assets/Images/HomeScreen/united-states.png" alt="UE" /> CH
                    </a>
                    <a className="dropdown-item" href="javascript:void(0)"><img className="img-fluid" src="./Assets/Images/HomeScreen/china.png" alt="UE" /> EN</a>
                  </div>
                </div>
                <div>
                  <img src="./assets/Images/Rewards/Group 91.png" className="img-fluid" />
                  <h4 className="mt-3">Setup Creator Profile</h4>
                  <form>
                    <div className="row mt-3">
                      <div className="col">
                      <input
                          class="form-control"
                          value={this.state.username}
                          name="username"
                          onChange={this.onChange}
                          type="text"
                          placeholder="   Username"
                          required="required"
                        />

                      </div>
                      <div className="col">
                      <input
                          class="form-control"
                          value={this.state.email}
                          name="email"
                          onChange={this.onChange}
                          type="email"
                          placeholder="   Email Address"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                      <input
                          class="form-control"
                          value={this.state.location}
                          name="location"
                          onChange={this.onChange}
                          type="text"
                          placeholder="   Where are your from?"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                
                        <textarea className="form-control"  name="message" value={this.state.message} onChange={this.onChange} placeholder="What are 3 of the most important things that define who you are as an artist?" rows={2} defaultValue={""} />
                      </div>
                    </div>
                    <div className="agree-section mt-5">
                      <div>
                        <input type="checkbox" id="agree" defaultChecked />
                        <label htmlFor="agree" />
                      </div>
                      <div className="terms-and-conditions-signup">
                        <a href="terms-and-conditions.html" target="_blank">   
                          <p>I agree to Reagent <b>Terms &amp; Conditions</b></p>
                        </a>
                      </div>
                    </div>
                    <button type="button" className="mt-5" onClick={this.onSubmit}>
                      <a href="unsupported-browser.html">
                        Continue
                      </a>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SignUP Section End*/}
        {/* JQuery Start */}
        {/*Plugin CSS file with desired skin*/}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.1/css/ion.rangeSlider.min.css" />
        {/*Plugin JavaScript file*/}
      </div>
          
		)
	}
}

export default Signup
