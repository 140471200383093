import React from "react"

class InstallMetaMask extends React.Component {
    render() {
        return (
            <div>
                {/* SignUP Section */}
                <div className="Signup-section Signup-section2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 a-side">
                                <img src="./assets/Images/Rewards/Group 1.png" className="img-fluid reagent-logo"
                                     alt="logo"/>
                                <h1> Register As Creator </h1>
                                <p>Register as creator with us and sell your NFTs on our platform exclusively. </p>
                                <h4>How we work?</h4>
                                <p>
                                    We generally take 12% commission and for the artist to apply they have to upload 4
                                    pieces
                                    of work that is ready for us to mint with title, links to their instagram or
                                    website</p>
                                <button type="button" href="javascript:void(0)" className="mt-4">
                                    Check Our FAQs Section
                                </button>
                                <div className="copyright">Copyrights @ 2021 Reagent, All rights reserved.</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 b-side">
                                <div className="dropdown border rounded">
                                    <a className="dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="./assets/Images/HomeScreen/united-states.png" className="img-fluid"
                                             alt="UE"/>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="javascript:void(0)">
                                            <img className="img-fluid"
                                                 src="./Assets/Images/HomeScreen/united-states.png" alt="UE"/> CH
                                        </a>
                                        <a className="dropdown-item" href="javascript:void(0)"><img
                                            className="img-fluid" src="./Assets/Images/HomeScreen/china.png"
                                            alt="UE"/> EN</a>
                                    </div>
                                </div>
                                <div>
                                    <img src="./assets/Images/Modals/metamask-fox.png" className="img-fluid"/>
                                    <h4 className="mt-5">Install MetaMask</h4>
                                    <p>Based on Your Browser</p>
                                    <div className="select-browser">
                                        <a href="register-art-work.html" className="chrome cursor">
                                            <i className="fab fa-chrome"/>
                                            <h5>Install on Chrome</h5>
                                        </a>
                                        <a href="register-art-work.html" className="FireFox curser">
                                            <i className="fab fa-firefox-browser"/>
                                            <h5>Install on Firefox</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SignUP Section End*/}
                {/* JQuery Start */}
                {/*Plugin CSS file with desired skin*/}
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.1/css/ion.rangeSlider.min.css"/>
                {/*Plugin JavaScript file*/}
            </div>
        );

    }

}

export default InstallMetaMask
    