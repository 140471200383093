import React from "react"
import Footer from './footer';
import RewardHeader from './Header-banners/Reward';
import Navigation from './navigation';
import setAddress from '../utils/MetamaskInject'

class Reward extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}
componentDidMount = async ()=> {
  const add = await setAddress()
  if(add){
   this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 


componentWillMount =  async ()=> {
  const add = await setAddress()
  if(add){
      this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 
	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
			<>
            <body>
                <Navigation/>
                <RewardHeader/>

                <section className="Reward-section container">
        <div className="row">
          {/* Congratulations Section */}
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 a-side">
            <div className="congratulation">
              <img src="./assets/Images/Rewards/Group 1977.png" className="img-fluid" alt="reward" />
              <h4>Congratulations!</h4>
              <p>You have earned our reward coins, which you can use to get
                discounts on purchasing NFTs. </p>
            </div>
            <div className="balance">
              <h5>Current Balance!</h5>
              <p><img src="./assets/Images/Rewards/Group 1657.png" className="img-fluid mr-3" alt="reward" /> 1930.60 </p>
            </div>
            <div className="faqs">
              <h5>FAQs</h5>
              <p>What is reward programm? <i className="fas fa-share-square ml-5" /></p>
              <p>How can I use my reward coins and where? <i className="fas fa-share-square ml-5" /></p>
              <p>How can I use my reward coins and where? <i className="fas fa-share-square ml-5" /></p>
            </div>
          </div>
          {/* Transaction Section */}
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 b-side">
            <div className="Transaction">
              <h4>Transaction History</h4>
              <div className="history">
                <div className="history-section">
                  <i className="fas fa-history" />
                  <p>Sorry! You have no history available.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
            
      <Footer />
</body>

    </>
    )
	}
}

export default Reward
