import React from "react"

class AccountCollectionsHeader extends React.Component {
  
	render() {
		return (
			<>


<section className="Marketplace-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <h2> My Collection </h2>
              <p>All Your Purchased Items</p>
            </div>
          </div>
        </div>
      </section>
      	</>
		)
	}
}

export default AccountCollectionsHeader
