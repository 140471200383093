import React from "react"
import AccountCollectionsHeader from './Header-banners/AccountCollections'
import FeaturedViewSection from './featuredView';

import Navigation from './navigation'
import Sidebar from './sidebar';
import Footer from './footer';

import setAddress from '../utils/MetamaskInject'



class AccountCollections extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}

     openNav() {
      document.getElementById("mySidenav").style.width = "260px";
    }

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    }



    componentDidMount = async ()=> {
      const add = await setAddress()
      if(add){
       this.setState({loggedAddress:add})
      }
      else{
        this.props.history.push("/login")
      }
      
    }; 
  
  
    componentWillMount =  async ()=> {
      const add = await setAddress()
      if(add){
          this.setState({loggedAddress:add})
      }
      else{
        this.props.history.push("/login")
      }
      
    }; 

	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
      
			<>
        < Navigation />
<AccountCollectionsHeader/>
<section className="Featured-section Marketplace-featured container">
          <div className="row mt-5">
            <div className="siderbar-logo" onClick= {() => this.openNav()}>
              <img src="./assets/Images/MarketPlace/Group 31.png" className="img-fluid search-sidebar-icon" alt="search-bar" />
            </div>
            {/* 1st Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 143.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            {/* 2nd Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 14.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> PAPER BOY by <b>Yiliang
                          Yang
                        </b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 151.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            {/* 3rd Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 15.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 153.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            {/* 4th Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 30.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <a href="ntf-details.html" target="_blank">  <img src="./assets/Images/HomeScreen/Rectangle 222.png" className="img-fluid card-img-bottom" /></a>
              </div>
            </div>
          </div>
        </section>
        {/* Featured Section End*/}
        {/* Sidebar Start */}
        <div className="position-relative">
          <div id="mySidenav" className="sidenav shadow">
            <div className="d-flex">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <div className="has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input type="text" className="form-control" placeholder="Search here" />
                </div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex">
                <a href="javascript:void(0)" className="product-btn pr-1">
                  <img src="./assets/Images/MarketPlace/physical.png" className="img-fluid" />
                  {/* <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 p-2">
            <img src="./assets/Images/MarketPlace/Icon awesome-boxes.png" class="img-fluid" alt="icon-box">
          </div>
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 p-2">
            <span> Physical</span>
            <span>Products</span>
          </div> */}
                </a>
                <a href="javascript:void(0)" className="digital-btn">
                  <img src="./assets/Images/MarketPlace/digital.png" className="img-fluid" />
                </a>
              </div>
            </div>
            {/* Collections Section */}
            <div className="collections-view mt-5">
              <div className="d-flex">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 justify-content-between d-flex">
                  <h4>Collections</h4>
                  <a href="#">View More</a>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <i className="fas fa-check-circle" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Yiliang Yang</h3>
                  <i className="fas fa-times" />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <i className="fas fa-check-circle" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Zejian Shan</h3>
                  <i className="fas fa-times" />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <img src="./assets/Images/MarketPlace/Rectangle 199.png" className="img-fluid" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Paper Boy</h3>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <img src="./assets/Images/MarketPlace/Rectangle 200.png" className="img-fluid" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Lost in Stereo</h3>
                </div>
              </div>
            </div>
            {/* Categories Section */}
            <div className="collections-view mt-5">
              <div className="d-flex">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 justify-content-between d-flex">
                  <h4>Categories</h4>
                  <a href="#">View More</a>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <i className="fas fa-check-circle" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Digital Art</h3>
                  <i className="fas fa-times" />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <i className="fas fa-check-circle" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Physical Products</h3>
                  <i className="fas fa-times" />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <img src="./assets/Images/MarketPlace/Rectangle 199.png" className="img-fluid" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Motion Graphics</h3>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <img src="./assets/Images/MarketPlace/Rectangle 200.png" className="img-fluid" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Music</h3>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                  <img src="./assets/Images/MarketPlace/Rectangle 206.png" className="img-fluid" />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                  <h3>Animation</h3>
                </div>
              </div>
            </div>
            {/* DropDown Section */}
            <div className="collections-view mt-5">
              <div className="d-flex">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <h4>Price</h4>
                  <select className="form-control mt-2">
                    <option>USDT</option>
                    <option>USDT</option>
                  </select>
                  {/* Range Section */}
                  <div className="d-flex justify-content-center my-4">
                    <span className="min">MIN</span>
                    <form className="range-field w-100">
                      <input className="border-0 w-100" type="range" min={0} max={5000} />
                    </form>
                    <span className="max">MAX</span>
                  </div>
                  {/* <input type="text" id="demo_0" name="my_range" value="" data-type="double" data-grid="true" /> */}
                </div>
              </div>
            </div>
            {/* Buttons Section */}
            <div className="buttons-view pt-4 pb-4 border-top">
              <div className="d-flex">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex">
                <button type="button" className="cancel mr-1" onClick= {() => this.closeNav()}>Cancel</button>
                <button type="button" className="apply" onClick= {() => this.closeNav()}>Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
            <Sidebar/>
        <Footer/>
      	</>
		)
	}
}

export default AccountCollections
