  module.exports = {

        USER_ID: function () {
            return "user_DLrSf5uDvoQ5538yM3jVQ";
        },
    
        
        TEMPLATE_ID: function () {
            return "template_w9ehtkf";
        },

        SERVICE_ID: function () {
            return "service_zlkk577";
        } 
    }