import React from "react"

import setAddress from '../utils/MetamaskInject'

class CreatorSubmission extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}

componentDidMount = async ()=> {
  const add = await setAddress()
  if(add){
   this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 


componentWillMount =  async ()=> {
  const add = await setAddress()
  if(add){
      this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 
	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
			<>
            <body>

            <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="javascript:void(0)">
            <img src="./assets/Images/HomeScreen/Reagent_logo.png" className="img-fluid reagent-logo" alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown border rounded">
                <a className="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="./assets/Images/HomeScreen/united-states.png" className="img-fluid" alt="UE" />
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="javascript:void(0)">
                    <img className="img-fluid" src="./Assets/Images/HomeScreen/united-states.png" alt="UE" /> CH
                  </a>
                  <a className="dropdown-item" href="javascript:void(0)"><img className="img-fluid" src="./Assets/Images/HomeScreen/china.png" alt="UE" /> EN</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>










            <div className="Upload-ArtWork">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 a-side">
              <h3>Upload Your Artwork </h3>
              <p>You are required to submit 4 Artworks to get qualified for review process.</p>
              <form>
                <div className="drop-zone mt-5">
                  <div className="drop-zone__prompt">
                    <i className="fas fa-upload" />
                    <h5>Upload Your Artwork</h5>
                    <span>Drag and drop the art here…</span>
                  </div>
                  <input type="file" name="myFile" className="drop-zone__input" />
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Title for Artwork" />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <textarea className="form-control" placeholder="What inspire you to make this art?" rows={4} defaultValue={""} />
                  </div>
                </div>
                <div className="upload-set mt-5">
                  <div className="required">
                    Required: 1/4
                  </div>
                  <button type="button" href="javascript:void(0)">
                    <a href="upload-art-work.html">
                      Upload Artwork
                    </a>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 b-side">
              <div className="lists">
                <img src="./assets/Images/Rewards/Rectangle 350.png" className="img-fluid" />
                <div>
                  <h4>Paper boy </h4>
                  <p>Ex modi voluptate sed. Molestiae ducimus praesentium ut
                    nobis harum iste vitae. Ut sint tenetur sapiente nisi illo
                    voluptas. </p>
                </div>
                <i className="fas fa-times ml-4" />
              </div>
              <div className="botom-section">
                <div className="agree-section mt-5">
                  <div>
                    <input type="checkbox" id="agree" defaultChecked />
                    <label htmlFor="agree" />
                  </div>
                  <div>
                    <p>I agree to Reagent <b>Terms &amp; Conditions</b></p>
                  </div>
                </div>
                <button type="button" href="javascript:void(0)" className="mt-5">
                  <a href="javascript:void(0)">
                    Register Now
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
            </body>

                </>
    )
	}
}

export default CreatorSubmission