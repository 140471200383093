import React from "react"

class MarketPlaceHeader extends React.Component {
  
	render() {
		return (
			<>


<section className="Marketplace-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <h2> We Are Open For Business </h2>
              <p> Collect From Our Huge Stock of Creative Art &amp; Products </p>
            </div>
          </div>
        </div>
      </section>
      	</>
		)
	}
}

export default MarketPlaceHeader
