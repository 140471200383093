import actionTypes from './actionType'

const initialState = {
    collection_data:{},
    allCollection_name:{},
    asset_data:{},
    purchasdNFTHash:{},
    PhysicalStore:{},

   
}

export const collectionStore = (state = initialState, action) => {
    switch (action.type) {
        

        case actionTypes.GET_COLLECTION_DATA: {
            return {
                ...state,
                collection_data: action.payload
            }
        }

        case actionTypes.GET_ALLCOLLECTION_NAME: {
            return {
                ...state,
                allCollection_name: action.payload
            }
        }
        case actionTypes.GET_ASSET_DATA: {
            return {
                ...state,
                asset_data: action.payload
            }
        }
        case actionTypes.PURCHASED_NFT: {
            return {
                ...state,
                purchasdNFTHash: action.payload
            }
        }

        case actionTypes.GET_PHYSICAL_DATA: {
            return {
                ...state,
                PhysicalStore: action.payload
            }
        }
        
        
        default:
            return state;


    }
}
