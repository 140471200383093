import React from 'react';
import Navigation from './navigation';
import SpecificArtWorkHeader from './Header-banners/SpecificArtWork'
import AccountCollectionsHeader from './Header-banners/AccountCollections'
import FeaturedArtWorkHeader from './Header-banners/FeaturedArtWork'
import LikedNFTsHeader from './Header-banners/LikedNFTs'
import MarketPlaceHeader from './Header-banners/MarketPlace'




import FirstViewSection from './firstView-section';
import FeaturedViewSection from './featuredView';
import Sidebar from './sidebar';
import Footer from './footer';
import setAddress from '../utils/MetamaskInject'





// import { connect } from "react-redux";
// import setAddress from '../utils/MetamaskInject'


class HomeFeatured extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          loggedAddress:""
        }
        
        
        const add =  setAddress()
        if(add){
    
        this.setState({loggedAddress:add})
        }
        else{
         this.props.history.push("/login")
        
        }
       
    }
    // constructor(props) {
    //     super(props);
    //     this.state = {

    //     };
    //     // const web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789");
    //     // console.log(web3)
    //     // this.checkMetaMask = this.checkMetaMask.bind(this);

    // }



    // checkMetaMask = async () => {
    //     const add = await setAddress()
    //     if(add){

    //         console.log("checkMetaMask",add)
    //         this.props.history.push("/dashboard",{ address:add })
    //     }
    //     else{
    //         alert("First connect Metamask!")
    //     }
        


    // }

    componentDidMount = async ()=> {
        const add = await setAddress()
        if(add){
         this.setState({loggedAddress:add})
        }
        else{
          this.props.history.push("/login")
        }
        
      }; 
    
    
      componentWillMount =  async ()=> {
        const add = await setAddress()
        if(add){
            this.setState({loggedAddress:add})
        }
        else{
          this.props.history.push("/login")
        }
        
      }; 
    render() {
        const add =  setAddress()
        if(add){
            console.log("checkMetaMask",add)
        }
        else{
         this.props.history.push("/login")
        }
        return (
       <body>
           
           < Navigation />

           {/* <  SpecificArtWorkHeader /> */}

           <  FirstViewSection />

           <FeaturedViewSection />
           
           <Sidebar />
          
          <Footer />
          </body>
        );
    }
}
export default HomeFeatured;
