import React, {Component} from 'react'
import {connect} from 'react-redux';
import MarketPlaceHeader from './Header-banners/MarketPlace'
import MarketPlaceSearch from './Header-banners/MarketPlaceSearch'
import Sidebar from './sidebar';

import Navigation from './navigation'
import Footer from './footer';

import setAddress from '../utils/MetamaskInject'
import {getSpecificCollection, getAllCollections, getSpecificAsset,getPhysicalCollection} from '../services/collection/action'

const JSJoda = require('js-joda');
const LocalDate = JSJoda.LocalDate;

class PhysicalStore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loggedAddress: "",
            collectionData: "",
            collectionName: ""

        }
        this.props.getPhysicalCollection()
        //  this.props.getAllCollections()
        this.searchAsset = this.searchAsset.bind(this)
        const add = setAddress()

        if (add) {
            //   this.props.history.push("/login")

            this.setState({loggedAddress: add})
        } else {

            this.props.history.push("/login")

        }

    }

    componentDidMount = async () => {
        const add = await setAddress()
        if (add) {
            // this.props.history.push("/login")
            this.setState({loggedAddress: add})
        } else {
            this.props.history.push("/login")
        }

        //this.props.getSpecificCollection('emperorofchina')
        this.props.getPhysicalCollection()

        //   if(this.props.collection_data){

        this.setState({
            collectionData: this.props.PhysicalStore
            //  ,collectionName:this.props.allCollection_name
        })
        //  console.log("this.props.collection_data:",this.props.collection_data)

        //  }


    };


    componentWillMount = async () => {
        const add = await setAddress()
        if (add) {
            //  this.props.history.push("/login")
            this.setState({loggedAddress: add})
        } else {
            this.props.history.push("/login")
        }

        //if(this.props.collection_data){

        //  this.setState({collectionData:this.props.collection_data})
        //   console.log("this.props.collection_data:",this.props.collection_data)

        //}
        this.props.getPhysicalCollection()

    };


    async componentWillReceiveProps(nextProps) {
        // console.log("collectionData:", nextProps.collection_data);
        // var
        // nextProps.collection_data.map(info=>{

        // })

        this.props.getPhysicalCollection()

        this.setState({
            collectionData: nextProps.PhysicalStore,
            //  collectionName:nextProps.allCollection_name
        });
        // this.setState({ result: object });
    }


    openNav() {
        document.getElementById("mySidenav").style.width = "260px";
    }

    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }


    searchAsset(data) {
// console.log("clicked....",data)
//     alert("clicked....")
        this.props.getSpecificAsset(data.token_id)
        this.props.history.push("/nftDetail")


    }

    getNumberOfDays(start, end) {
        const start_date = new LocalDate.parse(start);
        const end_date = new LocalDate.parse(end);

        return JSJoda.ChronoUnit.DAYS.between(start_date, end_date);
    }

    render() {
        //  console.log("data:",this.state.collectionData)

        // var d =this.state.collectionData
        //   this.state.collectionData.map(data=>{
        //   console.log("data:",this.state.collectionData)
        // })
        const add = setAddress()
        if (add) {
            //  this.props.history.push("/login")
            console.log("checkMetaMaskokokk", add)
        } else {
            this.props.history.push("/login")
        }
        return (
            <>
                <body>
                < Navigation/>
                <MarketPlaceHeader/>

                <section className="Featured-section Marketplace-featured container">
                    <div className="row mt-5">
                        {/* 1st Card */}

                        {this.state.collectionData[0] ? this.state.collectionData.map(data => {
                            console.log(data)
                                // console.log("data:",data.sell_orders[0].current_bounty)
                                return (
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
                                        <div className="card border-0">
                                            <div className="card-body no-padding">
                                                <div className="row mx-0">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                                                        {data? <img src={data.images[0].src} className="img-fluid"/> : null}
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                                                        <p>      
                                                        <a href={data.permalink} target="_blank" >  {data.name} </a>


                                                          
                                                          

                                                        </p>
                                                        <span>
                    {
                        // data.sell_orders != null && data.sell_orders[0].closing_date != null ?
                        //     this.getNumberOfDays(data.sell_orders[0].created_date.split("T")[0], data.sell_orders[0].closing_date.split("T")[0]) + " days left"
                        //     : `-`
                    }
                      </span>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                )
                            }
                        ) : null}


                    </div>

                </section>


                <Footer/>
                </body>
            </>
        )
    }


}

const mapStateToProps = (state) => ({
// eth_history: state.historyStore.eth_history,
    collection_data: state.collectionStore.collection_data,
    allCollection_name: state.collectionStore.allCollection_name,
    PhysicalStore:state.collectionStore.PhysicalStore
});


export default connect(mapStateToProps, {
    getSpecificCollection,
    getAllCollections,
    getSpecificAsset,
    getPhysicalCollection
})(
    PhysicalStore
);
