import React from "react"
import setAddress from '../utils/MetamaskInject'

class FirstViewSection extends React.Component {
	constructor(props){
		super(props)
		this.state = {
		  loggedAddress:""
		}
		
		
		const add =  setAddress()
		if(add){
	
		this.setState({loggedAddress:add})
		}
		else{
		 this.props.history.push("/login")
		
		}
	   
	}

	componentDidMount = async ()=> {
		const add = await setAddress()
		if(add){
		 this.setState({loggedAddress:add})
		}
		else{
		  this.props.history.push("/login")
		}
		
	  }; 
	
	
	  componentWillMount =  async ()=> {
		const add = await setAddress()
		if(add){
			this.setState({loggedAddress:add})
		}
		else{
		  this.props.history.push("/login")
		}
		
	  }; 
	render() {
		const add =  setAddress()
		if(add){
			console.log("checkMetaMask",add)
		}
		else{
		 this.props.history.push("/login")
		}
		return (
			<>


<div className="frist-view-logo">
        <img src="./assets/Images/MarketPlace/Group 25.png" className="img-fluid" alt="first-view" />
      </div>
      	</>
		)
	}
}

export default FirstViewSection
