import React from 'react';
import Navigation from './navigation';
import SpecificArtWorkHeader from './Header-banners/SpecificArtWork'
import AccountCollectionsHeader from './Header-banners/AccountCollections'
import FeaturedArtWorkHeader from './Header-banners/FeaturedArtWork'
import LikedNFTsHeader from './Header-banners/LikedNFTs'
import MarketPlaceHeader from './Header-banners/MarketPlace'


import { connect } from 'react-redux';


import FirstViewSection from './firstView-section';
import FeaturedViewSection from './featuredView';
import Sidebar from './sidebar';
import Footer from './footer';


import setAddress from '../utils/MetamaskInject'
import {getSpecificAsset, getSpecificCollection} from '../services/collection/action'



// import { connect } from "react-redux";
// import setAddress from '../utils/MetamaskInject'


class Home1 extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}
    // constructor(props) {
    //     super(props);
    //     this.state = {

    //     };
    //     // const web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789");
    //     // console.log(web3)
    //     // this.checkMetaMask = this.checkMetaMask.bind(this);

    // }



    // checkMetaMask = async () => {
    //     const add = await setAddress()
    //     if(add){

    //         console.log("checkMetaMask",add)
    //         this.props.history.push("/dashboard",{ address:add })
    //     }
    //     else{
    //         alert("First connect Metamask!")
    //     }
        


    // }

    componentDidMount = async ()=> {
      const add = await setAddress()
      if(add){
       this.setState({loggedAddress:add})
      }
      else{
        this.props.history.push("/login")
      }
      
    }; 
  
  
    componentWillMount =  async ()=> {
      const add = await setAddress()
      if(add){
          this.setState({loggedAddress:add})
      }
      else{
        this.props.history.push("/login")
      }
      
    }; 

     routeChange = () =>{ 
      // let path = `marketPlace`; 
     
      // this.props.history.push(`/marketPlace/pixelbart`)
      this.props.history.push(`/marketPlace:${'pixelbart'}`)


    }


    async searchAsset(){
      // console.log("clicked....",data)
      //     alert("clicked....")
        // await  this.props.getSpecificAsset('79630473148403579200232510153367869810807992404435473599666936436705676230657')
        await     this.props.getSpecificCollection('pixelbart')

        await  this.props.history.push(`/marketPlace:${'pixelbart'}`)
      
      
        }
        
    render() {
      const add =  setAddress()
      if(add){
          console.log("checkMetaMask",add)
      }
      else{
       this.props.history.push("/login")
      }
        return (
       <body>
           
           < Navigation />

           {/* Header Section */}
        <section className="header-section container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div>
                <h1> CUSCUS <br /> THE CUCKOO </h1>
                <p> / CUSCUS’S <b>NFT PARADISE </b> / </p>
                <button type="button" onClick={this.routeChange}>
                  Buy NFTs
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12" >
            <a href="#"    onClick={()=>{this.searchAsset()}}>  <img className="img-fluid" src="./assets/Images/HomeScreen/Rectangle 125.png" alt="header-design" /></a>  
            </div>
          </div>
        </section>
        {/* Header Section End*/}
        {/* Paradise Carousel Section */}
        <section className="Paradise-section container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h1 className="section-title">CUSCUS’S NFT PARADIS </h1>
              <p className="section-info"> by <b>Cuscus the Cuckoo</b> </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="owl-slider">
                <div id="carousel" className="owl-carousel">
                  {/* Item 1 */}
                  <div className="item">
                    <div className="card-image card-image-1" >
                      <img src="./assets/Images/HomeScreen/Rectangle 125.png" className="img-fluid" alt="1000X1000" />
                    </div>
                    <div className="show-section show-section-1">
                      <div className="hover-middle">
                        <p>Princess Teddy | 泰迪公主
                        </p>
                        <a href="javascript:void(0)">Click to Open</a>
                      </div>
                      <div className="hover-bottom row mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>By Artist</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Ellipse 2.png" className="img-fluid mr-2" /> @reagent</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Published on</p>
                          <span>Jun 29, 2021</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Listed Price</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Path 1.png" className="img-fluid rate-img mr-2" />0.05</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Item 2 */}
                  <div className="item">
                    <div className="card-image card-image-2">
                      <img src="./assets/Images/HomeScreen/Item_01.png" className="img-fluid" alt="" />
                    </div>
                    <div className="show-section show-section-2">
                      <div className="hover-middle">
                        <p>Princess Teddy | 泰迪公主
                        </p>
                        <a href="javascript:void(0)">Click to Open</a>
                      </div>
                      <div className="hover-bottom row mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>By Artist</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Ellipse 2.png" className="img-fluid mr-2" /> @reagent</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Published on</p>
                          <span>Jun 29, 2021</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Listed Price</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Path 1.png" className="img-fluid rate-img mr-2" />0.05</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Item 3 */}
                  <div className="item">
                    <div className="card-image card-image-3">
                      <img src="./assets/Images/HomeScreen/Item_02.png" className="img-fluid" alt="" />
                    </div>
                    <div className="show-section show-section-3">
                      <div className="hover-middle">
                        <p>Princess Teddy | 泰迪公主
                        </p>
                        <a href="javascript:void(0)">Click to Open</a>
                      </div>
                      <div className="hover-bottom row mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>By Artist</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Ellipse 2.png" className="img-fluid mr-2" /> @reagent</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Published on</p>
                          <span>Jun 29, 2021</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Listed Price</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Path 1.png" className="img-fluid rate-img mr-2" />0.05</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Item 4 */}
                  <div className="item">
                    <div className="card-image card-image-4">
                      <img src="./assets/Images/HomeScreen/Item_01.png" className="img-fluid" alt="" />
                    </div>
                    <div className="show-section show-section-4">
                      <div className="hover-middle">
                        <p>Princess Teddy | 泰迪公主
                        </p>
                        <a href="javascript:void(0)">Click to Open</a>
                      </div>
                      <div className="hover-bottom row mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>By Artist</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Ellipse 2.png" className="img-fluid mr-2" /> @reagent</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Published on</p>
                          <span>Jun 29, 2021</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Listed Price</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Path 1.png" className="img-fluid rate-img mr-2" />0.05</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Item 5 */}
                  <div className="item">
                    <div className="card-image card-image-5">
                      <img src="./assets/Images/HomeScreen/Rectangle 125.png" className="img-fluid" alt="1000X1000" />
                    </div>
                    <div className="show-section show-section-5">
                      <div className="hover-middle">
                        <p>Princess Teddy | 泰迪公主
                        </p>
                        <a href="javascript:void(0)">Click to Open</a>
                      </div>
                      <div className="hover-bottom row mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>By Artist</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Ellipse 2.png" className="img-fluid mr-2" /> @reagent</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Published on</p>
                          <span>Jun 29, 2021</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          <p>Listed Price</p>
                          <span className="d-flex mt-1 align-items-center"> <img src="./assets/Images/HomeScreen/Path 1.png" className="img-fluid rate-img mr-2" />0.05</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Paradise Carousel Section End*/}
        {/* More Info Section */}
        <section className="More-info-section container pt-0">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <h1> So what is a NFT and how can <br />
                Reagent Corp help? </h1>
              <p> Well NFT, a Non-Fungible Token, is a very special piece of art encrypted with data that the masters of the
                crypto world cooked up. Each NFT is a signed piece of limited edition “print”
                that exists to be traded in the crypto universe. However, even in the crypto world, you need a guide—a guide
                to help you decide which art has authenticity and which art can add value
                and prestige to your kingdom of collectibles. </p>
              <div className="text-center">
                <button type="button" onClick={()=>  this.props.history.push("/marketPlace")}>
                  MORE ART
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* More Info Section End*/}
        {/* NewsLetter Subscription Section */}
        {/* <section className="Newsletter-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 no-padding">
                <div className="borders-add pl-3 pr-3">
                  <h3> How Reagent <br />
                    works? </h3>
                  <p> Whether you are an artist, a collector, or just
                    an amateur art admirer who never thought you
                    could be part of the avant-garde art universe. </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 no-padding">
                <div className="borders-add pl-3 pr-3">
                  <h3> Core Values of <br />
                    Reagent </h3>
                  <p> Here we don’t judge, nor do we label. You
                    are you, a unique being made of universal star
                    matter and we want you to just enjoy the ride,
                    no matter where this journy of NFT takes us. </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 no-padding">
                <div className="borders-add pl-3 pr-3">
                  <h3> Why Cuscus the <br />
                    Cuckoo? </h3>
                  <p> Here we don’t judge, nor do we label. You
                    are you, a unique being made of universal star
                    matter and we want you to just enjoy the ride,
                    no matter where this journy of NFT takes us. </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                <h3>Subscribe to<br />
                  <b>our newsletter</b>
                </h3>
                <input type="email" className="form-control mt-4" placeholder="Email Address" /><br />
                <button type="button" href="javascript:void(0)">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </section> */}
        {/* NewsLetter Subscription Section End*/}
        {/* Featured Section */}
     
        {/* <section className="Featured-section container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center">
              <h1> Featured Artwork &amp; Products
              </h1>
              <p className="info"> In <b>Marketplace</b> </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 143.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 14.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> PAPER BOY by <b>Yiliang
                          Yang
                        </b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 151.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 15.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 153.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 30.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 222.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 160.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> PAPER BOY by <b>Yiliang
                          Yang
                        </b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 157.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> made an offer for about
                        $795 on <b>Lost In Stereo</b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 158.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
              <div className="card border-0">
                <div className="card-body no-padding">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                      <img src="./assets/Images/HomeScreen/Ellipse 15.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                      <p><b>@c34</b> PAPER BOY by <b>Yiliang
                          Yang
                        </b>
                      </p>
                      <span>11 MINUTES AGO [view]</span>
                    </div>
                  </div>
                </div>
                <img src="./assets/Images/HomeScreen/Rectangle 223.png" className="img-fluid card-img-bottom" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center">
              <button type="button" href="javascript:void(0)">
                MORE ART
              </button>
            </div>
          </div>
        </section>
       
        */}
        {/* Featured Section End*/}
        {/* Stats Section */}
        {/* <section className="Stats-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <h1> MARKET STATS
                </h1>
                <p className="info"> Since 2018 </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                <h2> $46m
                </h2>
                <p> earned by artists </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                <h2> $24m
                </h2>
                <p> secondary sales </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                <h2> 7,171%
                </h2>
                <p> avg. resale value </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                <h2> 23K
                </h2>
                <p> NTFs Added </p>
              </div>
            </div>
          </div>
        </section> */}
        {/* Stats Section End*/}
        {/* Testimonials Section */}
        <section className="Testimonials-section container">
          <div className="row">
            {/* 1st Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <div className="card">
                <img data-toggle="modal" data-target="#ModalTestimonial" className="cursor-pointer card-img-top img-fluid" src="./assets/Images/HomeScreen/Mask Group_1.png" alt="Card image cap" />
                <div className="card-body">
                  <p className="card-text">Hello there, please let
                    us know what Reagent
                    Corp. can do for you </p>
                  {/* <span>Thanks for coming to our little home on the internet.
              Here at Reagent Corp, we love to make new friends
              and you are invited to be part of the movement.
              </br></br>
              Whether you are an artist, a collector, or just an
              amateur art admirer who never thought you could
              be part of the avant-garde art universe. Here we
              don’t judge, nor do we label. You are you, a unique
              being made of universal star matter and we want
              you to just enjoy the ride, no matter where this
              journy of NFT takes us. </span> */}
                </div>
              </div>
            </div>
            {/* 2nd Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <div className="card">
                <img data-toggle="modal" data-target="#ModalTestimonial" className="cursor-pointer card-img-top img-fluid" src="./assets/Images/HomeScreen/Mask Group_2.png" alt="Card image cap" />
                <div className="card-body">
                  <p className="card-text">Hello there, please let
                    us know what Reagent
                    Corp. can do for you ?</p>
                  {/* <span>Thanks for coming to our little home on the internet.
              Here at Reagent Corp, we love to make new friends
              and you are invited to be part of the movement.
              </br></br>
              Whether you are an artist, a collector, or just an
              amateur art admirer who never thought you could
              be part of the avant-garde art universe. </span> */}
                </div>
              </div>
            </div>
            {/* 3rd Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <div className="card">
                <img data-toggle="modal" data-target="#ModalTestimonial" className="cursor-pointer card-img-top img-fluid" src="./assets/Images/HomeScreen/Mask Group_3.png" alt="Card image cap" />
                <div className="card-body">
                  <p className="card-text">We can assure you Rea-
                    gent Corp can be your
                    trusted guide</p>
                  {/* <span>The value of art, any collectible, or crypto asset is
              determined by many different variables. But what
              is important and what has not been extensively
              studied is the bridge between how we value crypto
              art versus physical art. That is why we want to
              become that bridge.
              </br></br>
              Our NFTs are art created by verified and gifted
              artists. Artists with stories, artists that have a voice,
              and artists who’s works have a high potential to
              sustain real value.</span> */}
                </div>
              </div>
            </div>
            {/* 4th Card */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <div className="card">
                <img data-toggle="modal" data-target="#ModalTestimonial" className="cursor-pointer card-img-top img-fluid" src="./assets/Images/HomeScreen/Mask Group_4.png" alt="Card image cap" />
                <div className="card-body">
                  <p className="card-text">We can assure you Rea-
                    gent Corp can be your
                    trusted guide </p>
                  {/* <span>The value of art, any collectible, or crypto asset is
              determined by many different variables. But what
              is important and what has not been extensively
              studied is the bridge between how we value crypto
              art versus physical art. That is why we want to
              become that bridge.
              </br></br>
              Our NFTs are art created by verified and gifted
              artists. Artists with stories, artists that have a voice,
              and artists who’s works have a high potential to
              sustain real value.</span> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials Section End*/}
        {/* Register Section */}
        <section className="Register-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <h4>Register As Creator
                </h4>
                <p> With <b>Reagent</b></p>
                <p className="mt-5"> Well NFT, a Non-Fungible Token, is a very special piece of art encrypted with data that the
                  masters of the
                  crypto world
                  cooked up. Each NFT is a signed piece of limited edition “print” that exists to be traded in the crypto
                  universe. However,
                  even in the crypto world, you need a guide—a guide to help you decide which art has authenticity and which
                  art
                  can
                  add value and prestige to your kingdom of collectibles.</p>
                <button type="button" onClick={()=>  this.props.history.push("/signup")} className="mt-4">
                  Register as Creator
                </button>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <h4 className="benefits-title">Key benefits for working with Us
                </h4>
                <ul className="mt-4">
                  <li>Well NFT, a Non-Fungible Token, is a very special piece of art encrypted with data
                  </li>
                  <li>that the masters of the crypto world cooked up. Each NFT is a signed piece of
                  </li>
                  <li>limited edition “print” that exists to be traded in the crypto universe. However,
                  </li>
                  <li>even in the crypto world, you need a guide—a guide to help you decide which art
                  </li>
                  <li>has authenticity and which art can add value and prestige to your kingdom of collectibles.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

           <div className="modal fade" id="ModalTestimonial" tabIndex="-1" aria-labelledby="ModalTestimonialLabel"
                aria-hidden="true">
             <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
               <div className="modal-content">
                 <div className="modal-header">
                   <h5 className="modal-title" id="ModalTestimonialLabel">Digital illustration trends 2022</h5>
                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                   </button>
                 </div>
                 <div className="modal-body">
                   <h3>Digital illustration trends 2022</h3>
                   <p>Digital illustration trends 2022 in the previous year
                     have evolved a lot. We see the rise of new trends in
                     the 2022 picture. We also saw a blow from the past:
                     some of the wonderful old-school illustration trends
                     return and make contemporary illustration design even
                     more fabulous.</p>
                   <p>2021 has been a year that none of us could anticipate
                     – A year to forget! It had brought turmoil & uncertainty
                     a few could have anticipated, yet there have still been
                     flashes of imagination, ingenuity, and daring.
                     Sometimes it was important to remember the past.
                     The year 2021 has been a year of contemplation! We
                     have to think about it since such occurrences shape
                     ourselves and our society throughout the future. Since
                     its beginnings, art & visual imagery has always been
                     utilized for healing and have played a major part at
                     such crucial moments, not just empathy, but most
                     crucially hope!</p>
                   <p>Freshly, at a time when we were all confined to the
                     security of our homes, we saw a huge rise in digital
                     arts and images. We have seen companies come out
                     with advanced and sophisticated techniques of
                     informing and informing as the entire globe is fighting
                     the epidemic. We observed that Google doodles
                     instructed us to stop the spread of coronavirus and
                     demonstrate empathy to the frontlines, especially with
                     the use of a mask by companies like Dunzo and other
                     such cases where the drawings spoke directly with the
                     people to provide an essential message.
                     We wanted to demonstrate in this blog what was
                     popular for 2021 and what is likely to be utilized
                     extensively this year also. Some of these
                     developments will have a big impact on 2022.
                     What, therefore, are the tendencies in illustration?
                     Consider what we, as illustration fans, believe will build
                     up a spirit all year long.</p>
                   <p>We’re going to go</p>

                   <h4>Illustration Abstract</h4>
                   <p>Although geometrical and abstract images are also a
                     little peculiar to your design in 2021, it is important not
                     to travel to the user. There is a delicate line between
                     creativity and confusion, which may start to get upset
                     fast. The imagination is reawoken by subtle and lively
                     geometric forms that produce a welcome sensation of
                     fun.</p>
                   <p>In the past, Abstraction saw an upward trend. This
                     approach enables us to understand immaterial topics.
                     There are no limits to abstraction and you can go as
                     far as you can dream and magically construct with
                     your own laws.</p>
                   <p>We are in love with this aesthetic and look forward to
                     seeing it more than we can understand</p>

                   <h4> Futurism: 3D illustrations </h4>
                   <p>3D futurism is part of the most incredible trends in
                     illustration 2022 with various strong colors and
                     shapes. Now, drawings may be produced in a 3D
                     environment and in a 2-dimensional area. It implies
                     they might visually have more volume and weight.
                     Many companies view 3D illustration as the greatest
                     way to make a brand look more appealing and
                     innovative. In other words, a business stands out on
                     the web with a futuristic feeling of 3D graphics.
                     From the past, the skeuomorphic style is returning to
                     life, and realistic graphics are on the increase. A recent
                     example of skeuomorphic iconography has been
                     found in the new Mac os, Big Sur. The threedimensional illustration style is the same with
                     businesses that invest in stylized but detailed 3d
                     graphics.</p>

                   <h4>3D Geometric</h4>
                   <p>3D drawings have great potential to convey concepts
                     that echo each one of us. Another technique that
                     takes plenty of time for investigation is a composition
                     that contains simple geometric illustrations and
                     texture. Budding designers may now build
                     compositions like the usability and accessibility of 3D
                     Software.</p>
                   <p>We also work & explore 3D design art with various
                     customers and projects! No better opportunity to
                     plunge into this new field of creativity and design.
                     There’s going to see you!!</p>
                   <h4>Outline Illustration (Digital illustration trends
                     2022)</h4>
                   <p>This specific drawing technique dominated
                     illustrations in 2020 when the outline becomes an
                     essential component of this composition. In many
                     situations, it is like drawings created many years ago.
                     We are confident that in 2022 this trend will remain
                     robust.</p>
                   <h4>Limited pallet (Digital illustration trends 2022)</h4>
                   <p>If you can work with a few of them artistically, you do
                     not need to employ a whole range of colors. A ‘less-ismore effect is called by several designers. You may
                     add more flair and refinement to your artwork with
                     numerous leading tones. He is surely going to stand
                     out in a digital environment as this trend has recently
                     entered the palette of the designer this year.
                     These limitations provide more air to the image and
                     make it appear new. We can see that the smallest
                     tones may be replaced with more strong fundamental
                     colors when reducing colors to essentials. This is one
                     of 2021’s most popular digital pictures, making images
                     appear remarkable.</p>

                   <p>( source: <a href="https://trendsdesignhugger.com/digitalillustration-trends-2022/" target="_blank">https://trendsdesignhugger.com/digitalillustration-trends-2022/</a>
                     )</p>
                   <h3>Money Isn’t the Only Reason
                     Auction Houses Are Jumping On
                     NFTs</h3>
                   <p>The record-breaking NFT auction at Christie’s last
                     month has been paying dividends to the 254 year-old
                     auction house well-beyond the $69.3 million price tag.
                     Not only did it result in torrents of publicity and a fat
                     commission, it also led to at least one major NFT
                     collector “crossing over” from crypto to buy a $20
                     million Picasso.</p>
                   <p>Now, as rivals Sotheby’s and Phillips announce the
                     details of their own auctions of NFTs (non-fungible
                     tokens are essentially certificates of authenticity tied
                     to digital artworks), a less visible upside has emerged:
                     new blood. Every time young, crypto-rich collectors
                     bid on an NFT, they are entered into the auction
                     house’s database.</p>
                   <p>“While the financial return is always an important
                     component, the success of my sale will be based on
                     the number of people who actually participated in the
                     sale,” says Max Moore, the co-head of Sotheby’s
                     contemporary art day sales in New York. “Through a
                     new medium and new creators, we are attracting a
                     whole new set of clients in a marketplace that’s
                     primarily driven by a younger demographic.” Rebekah
                     Bowling, a senior specialist at Phillips, echoes this
                     sentiment. “We’re certainly excited about engaging
                     this new community of collectors,” she says. “And of
                     course, we’re interested to see how their interests
                     evolve, and [whether] it will evolve into physical works
                     of art.”</p>
                   <p>Both Sotheby’s and Phillips have chosen to auction
                     artworks that, in whatever way, embrace the unique
                     possibilities of the digital medium. In Phillips’s case,
                     that means auctioning a digital image of a copy
                     machine by Michah Dowbak, who goes by the artist
                     name Mad Dog Jones, in an online-only sale that will
                     run from April 12 to 23. The opening bid is $100;
                     buyers can pay with fiat currency or in Ether. The NFT
                     attached to the image will replicate itself by creating a
                     new, unique NFT every 28 days or so; those NFTs will
                     then replicate themselves until seven generations of
                     replications have occurred.</p>
                   <p>The digital image attached to each NFT will change
                     every generation, too. “He calls it the story of a
                     machine through time,” Bowling says. “Paint will start
                     to peel, you’ll see new characters enter the scene,
                     potentially, and eventually it will be an [image] of
                     a machine overgrown in vines.”
                     By design, there will be some “jams” that happen,
                     Bowling explains, in which the replication process fails;
                     in total, she estimates that the original NFT will
                     produce about 220 NFTs (it could be more or less
                     depending on the jams), all of which will be owned by
                     the purchaser.</p>
                   <h4>Not Quite an Auction</h4>
                   <p>Sotheby’s is holding an even more unorthodox auction
                     of work by the anonymous digital designer Pak. For
                     starters, Sotheby’s isn’t actually hosting it. The
                     auction will take place on the NFT marketplace Nifty
                     Gateway, where payment is accepted in fiat and
                     cryptocurrencies.</p>
                   <p>It’s also not quite an auction. In a format that will be
                     familiar to Nifty Gateway’s users, the Pak sale will
                     occur over the course of three days, from April 12 to
                     14, and include seven separate components. On
                     Tuesday, Sotheby’s announced details of the first
                     component, which will be a sale of eight, openedition NFTs. Sotheby’s hasn’t said how long the sale
                     will last—previous, similar “drops” on Nifty Gateway
                     have been as short as a few minutes—but however
                     long it runs, an unlimited number of collectors will be
                     able to buy NFTs connected to digital images of
                     cubes.</p>
                   <p>An image of one cube will cost $500; an image of five
                     cubes will cost $2,500. The next six tiers will bump up
                     accordingly to 10 cubes, then 20, then 50, then 100,
                     then 500, then finally 1,000, which will cost $500,000.
                     Each of the eight tiers is connected to a single NFT,
                     meaning that if someone spends $503,000, they’ll get
                     three NFTs: one of one cube ($500 tier), one of five
                     cubes ($2,500 tier), and one of 1,000
                     cubes ($500,000 tier).</p>
                   <p>“It’s the most inclusive aspect of the auction,” says
                     Moore, given the price points and open edition.
                     Sotheby’s has yet to announced the six further
                     components of the Pak sale.</p>
                   <h4>Monetizing the Art</h4>
                   <p>NFT artists and collectors haven’t been shy about
                     trying to make money off the field. In fact, the
                     monetization of NFTs could almost be considered
                     integral to the medium. But in recent weeks, the whitehot market has begun to cool down. Last
                     week, Bloomberg reported that the average daily
                     volume of NFTs sold across marketplaces had fallen
                     from $19.3 million to $3.03 million as of March 25.</p>
                   <p>The works at both Phillips and Sotheby’s could be
                     viewed as tests of—and boosts for—this new,
                     stagnating market. Given that the NFT by Mad Dog
                     Jones will generate many more NFTs and thus
                     potential revenue, the artwork includes a question of
                     “token-omics” says Bowling. “Is there value in scarcity,
                     or is there value in the ability to produce a piece that
                     has so many more pieces?” Potentially, a buyer could
                     take all the NFTs produced in the series
                     and fractionalize them, she says; alternately, the buyer
                     might find more value in selling them individually, or
                     flipping them as a group.</p>
                   <p>And while Sotheby’s has yet to release more
                     information about the other Pak artworks, Moore says
                     that the open edition’s resale market was “absolutely a
                     consideration” in their planning, though he declines to
                     elaborate. “You’ll have to wait and see,” he says.
                     What’s clear is that all participants, at least for the
                     time being, are positioned to benefit from established
                     auction houses putting their name behind NFTs. The
                     upside for the artists is clear: The NFT market is only a
                     few years old, and it doesn’t enjoy the respectability
                     or institutional imprimatur possessed by other
                     mediums. These auctions could help change that.</p>
                   <p>“It’s interesting to see these creators, how they’re
                     interested in having major auction houses promote
                     their work,” says Bowling. “I think they understand
                     what they bring in terms of reach and scope and
                     context—and they want to be in the context of great
                     artists, and that’s why they’re looking to us.”</p>

                   <p>( source: <a href="https://www.bloomberg.com/news/articles/
    2021-04-06/money-isn-t-the-only-reason-auctionhouses-are-jumping-on-nfts" target="_blank">https://www.bloomberg.com/news/articles/
                     2021-04-06/money-isn-t-the-only-reason-auctionhouses-are-jumping-on-nfts</a>
                     )</p>

                   <h3>The art of collaborating</h3>
                   <p>Whether it’s Louis Vuitton and the Chapman brothers
                     or Damien Hirst and Converse, brand and artist
                     collaborations are becoming the new norm. In the US,
                     brands will spend more than $1bn on cultural
                     sponsorship this year and a large chunk of this will be
                     based around artistic partnerships. This could involve
                     a brand creating special artwork for a product, doing a
                     mural advertising campaign, or even championing new
                     artists.</p>
                   <p>Brands collaborating with artists can gain credibility
                     with customers, make a political statement and,
                     ultimately, become a part of art history. We’ve taken a
                     look at five ways brands can ensure artist
                     collaborations are an enduring success.</p>
                   <h4>Choose wisely</h4>
                   <p>Before browsing through the portfolios of emerging
                     artists or hiring an art consultant, brands need to
                     identify exactly why they want to collaborate with an
                     artist and what they can expect from it. There are
                     countless forms of collaboration and picking the right
                     one is only possible if brands know their end goals. Do
                     you want to make your brand more known among a
                     younger audience or within artistic circles? If this is
                     the case then developing a product with an artist who
                     is praised by millennials is probably a better idea than
                     sponsoring the Venice Biennale. Gucci’s partnership
                     with Trevor Andrew to design a streetwear collection is
                     a great example of how an established brand can win
                     the hearts of younger customers, with these
                     collaborations giving it an instant bond with the
                     respective artist’s Instagram followers.</p>
                   <h4>Have clear, consistent messaging</h4>
                   <p>One of the biggest risks for a brand and artist
                     collaboration is to be misunderstood by your
                     audience. This is what happened to Louis Vuitton
                     when the brand launched Masters in 2017, a capsule
                     collection in collaboration with Jeff Koons, with
                     controversy starting way before the collection even hit
                     Louis Vuitton’s stores. Koons revisited famous Louis
                     Vuitton bags, luggage and accessories by printing
                     reproductions of some of the most famous paintings
                     of artists such as Van Gogh, Da Vinci and Rubens. But
                     the meaning of the collaboration was lost on followers
                     of the brand, who expressed confusion over the
                     aesthetics of the bags. Were they genuinely fresh
                     takes on masterpieces or items that would be better
                     suited to touristy gift shops? Was Jeff Koons trying to
                     question mass tourism in cultural institutions, or did he
                     just want to make this collaboration a commercial
                     success by featuring mainstream paintings? The fact
                     we’re asking so many questions is a great indicator of
                     why the collaboration failed; you must have clear,
                     consistent brand messaging if an artist collaboration is
                     to bare fruit.
                   </p>
                   <h4>Make a long-lasting impact</h4>
                   <p>Rather than creating limited edition products, brands
                     can have a more long-lasting impact on the art world
                     by helping emerging artists to find work. This could
                     come through sponsoring exhibitions at museums or
                     galleries, starting a fund to help young artists, or cohosting shows to help them share their work with the
                     public. French department store chain Galeries
                     Lafayette’s Lafayette Anticipations foundation not only
                     exhibits artwork by young artists but also offers them
                     resources so they can create. A studio has been built
                     inside the flagship store to welcome artists while they
                     work on their upcoming shows.</p>
                   <h4>Don’t cut corners</h4>
                   <p>When brands and artists decide to work together on a
                     short deadline, using existing artworks can sometimes
                     be a more flexible option. But reproducing an artist’s
                     work should be done with caution, and the artist’s full
                     approval must be sought out. Earlier this year, H&M
                     photographed models in front of a street art piece by
                     REVOK for a marketing campaign, without asking the
                     artist for permission. REVOK’s lawyer sent a cease and
                     desist letter to H&M, requesting the posters to be
                     removed. When H&M decided to sue the artist, all it
                     gained was anger and isolation from the street art
                     community, especially on social media. Therefore, it’s
                     imperative not to cut corners and to do things as
                     faithfully as possible. Communication with artists is
                     everything.</p>
                   <h4>Make art more accessible</h4>
                   <p>Art comes to life in front of an audience, so why not
                     help as many people as possible get access to it?
                     Brands can sponsor museums, art centres and artistic
                     events by offering their customers free access to
                     shows, concerts and performances. This is exactly
                     what Target did by funding hundreds of exhibitions
                     and cultural events in locations such as New York’s
                     Opera as well as the city’s Museo del Barrio museum.
                     More than 100 institutions benefitted from this
                     sponsorship program and were offered free or
                     reduced entry. For a brand with a large audience who
                     may not have easy access to art, democratising art is
                     a powerful way to engage with customers and show
                     you really care about the industry.</p>
                   <p>( source: <a href=" https://www.appearhere.us/inspire/blog/theart-of-brand-and-artist-collaborations" target="_blank"> https://www.appearhere.us/inspire/blog/theart-of-brand-and-artist-collaborations</a>
                     )</p>
                   <h3>The new collector’s guide to
                     Asian contemporary art</h3>
                   <p>Who’s hot? Which regions are undervalued? How
                     important is an artist’s auction history? Specialist Aisi
                     Wang addresses these questions and more about a
                     fast-growing market</p>
                   <h4>Are editions a good way to start an Asian art
                     collection?</h4>
                   <p>Editions are an excellent way to acquire a work by an
                     Asian artist you admire at an accessible price point.
                     Artists who offer high-quality editions of images from
                     their iconic series include Liu Ye, Yoshitomo
                     Nara and Yayoi Kusama.</p>
                   <h4>An artist I admire is best known for paintings.
                     Should I collect the same artist’s works on
                     paper or sculpture?</h4>
                   <p>Works on paper and sculpture can give you a different
                     perspective on an artist’s process. The works on
                     paper of Yayoi Kusama, for example, offer the
                     possibility of an alternative interpretation of the
                     paintings that made her famous. Similarly, Haegue
                     Yang’s mixed-media flat works can be compared with
                     her installations. Works on paper and sculpture
                     complement the artist’s primary medium, and can be
                     collected and appreciated as works in their own right.</p>
                   <h4>Should I focus on a particular region?</h4>
                   <p>There’s no need to limit yourself. In the past the
                     market for Asian artists was more regionally focused
                     — Chinese collectors only seeking out works by
                     Chinese artists, for example — but now we’re seeing
                     more collectors acquiring works regardless of
                     geography or nationality.
                     Artists from Asia are increasing their international
                     presence and enjoying more attention from collectors
                     globally. One example of this is Zao Wou-Ki, who has
                     long been considered a modern master in Asia but has
                     only recently received the same level of recognition in
                     the United States. There have been Zao retrospectives
                     in Taiwan since the mid-1990s, but it wasn’t until 2016
                     that the first was held in an American museum, at
                     the Asia Society in New York.</p>
                   <h4>What impact does the condition of an artwork
                     have on its value?</h4>
                   <p>That depends on the artwork and the artist. Takashi
                     Murakami’s prints, for example, should be in pristine
                     condition — anything less would negatively affect the
                     value of the work. For some artists, however, what
                     may appear to be deterioration or damage can be an
                     integral part of their practice. Walasse Ting’s works on
                     paper are often creased because he saturates thin
                     Chinese paper with brightly coloured ink or acrylic
                     paint, and the wetting and drying of the paper and
                     mixing of different media affects the condition of the
                     work. The resulting marks of imperfection would be
                     considered a part of the artist’s technique, and not a
                     reflection of the artwork’s quality</p>
                   <h4>Which is more important to consider, market
                     value or historical value?</h4>
                   <p>Market value doesn’t necessarily reflect the historical
                     value of an artwork. The Taiwanese Fifth Moon and
                     Ton Fan groups, for example, which included
                     renowned artists such as Hsiao Chin, Li Yuan-Chia,
                     and Hu Chi-Chung, are undervalued relative to their
                     significance in the history of Asian post-war abstract
                     art. Tsuyoshi Maekawa is another example of an
                     undervalued artist. He was a pioneering member of
                     the Japanese Gutai group, who experimented with
                     performance and painting — its most famous member
                     being Kazuo Shiraga, who painted with his feet. Some
                     of Shiraga’s paintings now sell for millions of dollars; in
                     contrast, Maekawa’s works are relatively affordable
                     when they appear at auction.</p>
                   <h4>What are the prospects for Southeast Asian art?</h4>
                   <p>The market for Southeast Asian artists is growing
                     ●
                     rapidly, and shows immense potential. Many of these
                     artists have established careers and long sale histories
                     in their own countries, but until recently their market
                     has been very regional. Thanks to Biennales, art fairs
                     and international exhibitions, they’re becoming more
                     accessible to collectors across the world.  Since
                     Southeast Asian art is still an emerging market, the
                     works are affordable, making this category an
                     excellent entry point for new collectors. For example,
                     paintings by the Vietnamese artist Vu Cao Dam, who
                     spent much of his career living near Marc Chagall in
                     the south of France, can still be found for relatively low
                     prices.</p>
                   <h4>What is the trend in Asian contemporary
                     sculpture?</h4>
                   <p>We are finding many artists who are creating unique,
                     highly conceptual pieces that blur the lines between
                     sculpture and decorative art. For example, Zhan
                     Wang’s artificial rock sculptures echo the traditional
                     Chinese passion for ‘scholar’s rocks’ — fascinatingly
                     shaped pieces of natural stone.</p>

                   <p>( source: <a href="https://www.christies.com/features/The-newcollectors-guide-to-Asian-contemporaryart-8162-1.aspx" target="_blank">https://www.christies.com/features/The-newcollectors-guide-to-Asian-contemporaryart-8162-1.aspx</a>
                     )</p>
                 </div>
                 <div className="modal-footer">
                   <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                 </div>
               </div>
             </div>
           </div>
          <Footer />
          </body>
        );
    }
}

const mapStateToProps = (state) => ({
  // eth_history: state.historyStore.eth_history,
  // collection_data: state.collectionStore.collection_data,
  // allCollection_name: state.collectionStore.allCollection_name
  });
  
export default connect(mapStateToProps, {

  getSpecificAsset,    
  getSpecificCollection
})(
  Home1
);
