import React from "react"

class SpecificArtWorkHeader extends React.Component {
  
	render() {
		return (
			<>


<section className="Marketplace-header Featured-art-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <h2>Artwork by Yiling Yang </h2>
              <p>Collect of All Artwork Submitted by Yiling Yang </p>
              <div className="logo-img-set">
                <img src="./assets/Images/MarketPlace/Ellipse 19.png" className="img-fluid" alt="ellipse" />
              </div>
            </div>
          </div>
        </div>
      </section>
      	</>
		)
	}
}

export default SpecificArtWorkHeader
