import Web3 from 'web3';
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
const setAddress = async() =>{



    //var web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789");
    // var  web3 = new Web3('https://rinkeby.infura.io/v3/4b419e0704d34d808f7d50dc9cea686f');
    // const provider = new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/4b419e0704d34d808f7d50dc9cea686f')

    var  web3 = new Web3('https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789');
    const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789')


    const seaport = new OpenSeaPort(provider, {
            networkName: Network.Mainnet
          })
          console.log("seaport:",seaport)
        //   const asset= await seaport.api.getAsset({
        //     tokenAddress, // string
        //     tokenId, // string | number | null
        //   })
          

        // const { orders, count } = 
    //     await seaport.api.getOrders({
    //         asset_contract_address: "0x88B48F654c30e99bc2e4A1559b4Dcf1aD93FA656",
    //         token_id: "63092109242097632060224703250247562510273465452324362172338431122826237837313",
    //         side: OrderSide.Sell
    //       }).then(res=>{
    //    //     console.log("orders:",res.orders)
    //      console.log("count:",res.count)
    //       })
          

      
          // Get page 2 of all auctions, a.k.a. orders where `side == 1`
        //   const { orders, count } = await seaport.api.getOrders({
        //     asset_contract_address: tokenAddress,
        //     token_id: token_id,
        //     side: OrderSide.Sell
        //   }, 2)

    if (window.ethereum) {
         web3 = new Web3(window.ethereum);
        try {
            const account = await window.ethereum.enable();
            // const seaport = new OpenSeaPort(web3, {
            //     networkName: Network.Rinkeby
            //   })
            // this.state.account = account[0]
            // console.log(this.state.account)
            // console.log("seaport:",seaport)
            console.log("checkMetaMask",web3.eth.net.getNetworkType())
          const net = await  web3.eth.net.getNetworkType()
      
         // return
          //  web3.version.getNetwork((err, netId) => {
           
        //    if(netId!=1){

       
        //     toast.configure();
        //     toast.success("Please shift to Mainnet!" , {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 5000,
        //         toastId: 1,
        //     });
        //    }else{

            if(net == "main")
          { return web3.utils.toChecksumAddress(account[0])}
            else
        {    toast.configure();
                toast.error("Please shift to Mainnet!" , {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    toastId: 1,
                });
            }

        //    }
   
          //  })
    


           // alert('Metamask connected with address:',account[0]);
           
        }
        catch (e) {
            // User has denied account access to DApp...
        }
    }
    // Legacy DApp Browsers
    else if (window.web3) {
         web3 = new Web3(window.web3.currentProvider);
    }
    // Non-DApp Browsers
    else {
        alert('You have to install MetaMask !');
    }
}

export default setAddress;