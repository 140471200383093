import React from "react"
import {connect} from 'react-redux';
import Footer from './footer';
import Navigation from './navigation';
import setAddress from '../utils/MetamaskInject'
import {type} from "jquery";
import ReactTooltip from 'react-tooltip';
import "react-toastify/dist/ReactToastify.min.css";
import { Flip, toast } from "react-toastify";

import {PurchaseNFT, BuyOffer, CreateSellOffer} from '../services/collection/action'

class NftDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loggedAddress: "",
            assetDetails: 'null',
            p: 'null',
            details: '',
            orders: {},
            current_owner: '',
            send_amount:'',
            token_id:'',
            sell_amount:''
        }



        // if(!(this.props.asset_data) || this.props.asset_data=={} || this.props.asset_data==''){
        //   this.props.history.push("/marketPlace")
        // }



        const add = setAddress()
        if (add) {

            this.setState({loggedAddress: add})
        } else {
            this.props.history.push("/login")

        }

    }

    clickPurchase = async () => {
     //   alert("clicked")
        this.props.PurchaseNFT(this.state.loggedAddress,this.state.assetDetails.token_id)

    }


  //   clickSell = async () => {
  //     alert("clicked")
  //     this.props.PurchaseNFT(this.state.loggedAddress,this.state.assetDetails.token_id)

  // }


    clickMakeOffer = async () => {
console.log("aqeel::::",this.state.assetDetails.token_id)

        //alert("clicked",this.state.assetDetails.token_id)


        if(this.state.send_amount!=''){
          this.props.BuyOffer(this.state.loggedAddress,this.state.send_amount,this.state.assetDetails.token_id)


        }else{
          toast.configure();
          toast.error("Please pass valid amount", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
              toastId: 1,
          });
        }

    }


    clickSellOffer = async () => {
       // alert("clicked")

        
        this.props.CreateSellOffer(this.state.loggedAddress,this.state.sell_amount,this.state.assetDetails.token_id)

    }


    componentDidMount = async () => {
        console.log("componentDidMount", this.props.asset_data)
        const add = await setAddress()
        if (add) {
            this.setState({loggedAddress: add})
        } else {
            this.props.history.push("/login")
        }


        if (this.props.asset_data)
            this.setState({assetDetails: this.props.asset_data})


    };

    SendAmountChange = async (e) => {
      console.log("Math.round(e.target.value):",Math.round(e.target.value))
      await this.setState({  send_amount:   e.target.value});


  }

  SellAmountChange = async (e) => {
    console.log("Math.round(e.target.value):",Math.round(e.target.value))
    await this.setState({  sell_amount:   e.target.value});


}

    componentWillMount = async () => {
        console.log("componentWillMount", this.props.asset_data)
        const add = await setAddress()
        if (add) {
            this.setState({loggedAddress: add})
        } else {
            this.props.history.push("/login")
        }
        if (this.props.asset_data != '')
            this.setState({assetDetails: this.props.asset_data})


    };


    componentWillReceiveProps = async (nextProps) => {
        console.log("componentWillReceiveProps", nextProps.asset_data)
        if (nextProps.asset_data != this.props.asset_data)
            // this.setState({})
            //var current_owner =(this.state.orders!=undefined && this.state.orders.length>0  && this.state.orders!={})? this.state.orders[0].maker.address:''

            this.setState({
                assetDetails: nextProps.asset_data,
                p: 'pp',
                orders: nextProps.asset_data.orders,
                current_owner: nextProps.asset_data.orders.length>0?nextProps.asset_data.orders[0].maker.address:null
            })
        // nextProps.asset_data.orders.forEach(item=>{
        console.log("orders1:", nextProps.asset_data.orders)
        console.log("orders1:", this.state.orders)
        // })
    };


    text_truncate = function (str, length, ending) {
        if (length == null) {
            length = 33;
        }
        if (ending == null) {
            ending = '...........';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };


    render() {
      // const { asset_data } = this.props
      // if ( !(asset_data && asset_data.token_id) ) {
      //   this.props.history.push("/marketPlace")
      // }
      // if(!(this.props.asset_data) || this.props.asset_data=={} || this.props.asset_data==''){
      //   this.props.history.push("/marketPlace")
      // }
        console.log("assetDetails from state traits:", this.state.assetDetails)
        var details = [];
        this.props.collection_data.forEach(element => {
            //console.log("loop", element)
            if (element.id == this.state.assetDetails.id) {
                details = element
                details.orders = element.orders!=''?element.orders:null
            }
        });


//this.state.assetDetails.top_ownerships[0].quantity
        var totalCnt = 0
        if (this.state.p != 'null') {
            this.state.assetDetails.top_ownerships.forEach((record) => {
                totalCnt += Number(record.quantity)
            })
        }


        var add = ''
        add = setAddress()

        if (add) {
            console.log("checkMetaMask", add)
        } else {
            this.props.history.push("/login")
        }


        return (
            <>
                <body>
                <Navigation/>

                <p ref={ref => this.fooRef = ref} data-tip='tooltip'></p>

                <section className="NTFS-section NTFS-Detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12 pt-5">
                                {/* <h2> See First version </h2> */}
                                <img src={this.props.asset_data.image_url} className="img-fluid ImgContain ImgDetail"/>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="row b-side">
                                    <div
                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex align-items-center no-padding paper-title">
                                        <h3>{this.props.asset_data.name}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 no-padding">
                                        <ul className="listing-data">
                                            <li>
                                                {/* {this.props.asset_data.top_ownerships[0]?this.props.asset_data.top_ownerships[0].quantity:0} */}
                                                <img src="./assets/Images/NTFDetails/Icon ionic-ios-people.png"
                                                     className="img-fluid mr-2"/>{this.state.p == 'null' ? 0 : this.state.assetDetails.top_ownerships.length} Owner
                                            </li>
                                            <li>
                                                <img src="./assets/Images/NTFDetails/Icon awesome-boxes.png"
                                                     className="img-fluid mr-2"/>{totalCnt} Total
                                            </li>
                                            {/* <li>
                        <img src="./assets/Images/NTFDetails/Icon awesome-eye.png" className="img-fluid mr-2" />[34] Views
                      </li>
                      <li>
                        <img src="./assets/Images/NTFDetails/Icon metro-favorite-fill.png" className="img-fluid mr-2" />[16]
                        Favorites
                      </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 Current-price pl-0">
                                        <h5>Current Price:</h5>
                                        <p><img src="./assets/Images/NTFDetails/Icon awesome-ethereum.png"
                                                className="img-fluid mr-2"/>
                                            {details.sell_orders != null ?

                                                details.sell_orders[0].current_price / 10 ** 18 : 0}
                                            <small>[ {details.sell_orders != null ?

                                                (details.sell_orders[0].current_price / 10 ** 18) * details.sell_orders[0].payment_token_contract.usd_price : `-`}]</small>
                                        </p>


                                        
                                { this.state.current_owner==null ?
 <div className="buttons-view">
 <div className="d-flex">
     <div
         className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex no-padding">
         {/* <button type="button" className="Buy-btn mr-1"
                 onClick={() => this.clickPurchase()}>Buy Now
         </button> */}
         <button data-toggle="modal" data-target="#ModalMakeOffer" type="button" className="Offer-btn"
                 >Make an Offer
         </button>
     </div>
 </div>
</div>
                                :
                                
                                this.state.current_owner.toString().toLowerCase().toString() === this.state.loggedAddress.toString().toLowerCase().toString() ?

                                            <div className="buttons-view">
                                                <div className="d-flex">
                                                    <div
                                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex no-padding">
                             

                                                        {
                                                            details.sell_orders == null ?
                                                                <button data-toggle="modal" data-target="#ModalSell" type="button" className="Buy-btn mr-1"
                                                                        // onClick={() => this.clickSell()}
                                                                        >  Sell</button>
                                                                : null}


                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="buttons-view">
                                                <div className="d-flex">
                                                    <div
                                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex no-padding">
                                                        <button type="button" className="Buy-btn mr-1"
                                                                onClick={() => this.clickPurchase()}>Buy Now
                                                        </button>
                                                        <button data-toggle="modal" data-target="#ModalMakeOffer" type="button" className="Offer-btn"
                                                                >Make an Offer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        {/* <span> <img src="./assets/Images/NTFDetails/Icon metro-favorite.png" className="img-fluid mr-2" />
                            Favorite
                          </span> */}


                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 Properties no-padding">
                                        <h5>Properties:</h5>
                                        <p><img src="./assets/Images/NTFDetails/Icon material-fullscreen.png"
                                                className="img-fluid mr-2"/>{typeof details.traits == 'undefined' ? "-" : Object.keys(details.traits).length == 0 ? null : details != '' || details.traits != null ? details.traits[0].value : null}
                                        </p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 Details">
                                        <h5>Details:</h5>
                                        <div className="row mt-4 align-items-center">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                                                <p>Created By</p>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                                                <span><img src="./assets/Images/NTFDetails/Group 37.png"
                                                           className="img-fluid mr-2"/>{details.creator != null ? details.creator.user.username : null}</span>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                                                <p>Contract Address</p>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7 TextBreak">
                                                {details.asset_contract != null ? details.asset_contract.address : null}
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                                                <p>Token ID</p>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7 TextBreak">
                                                {details.token_id != null ? details.token_id : null}
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                                                <p>Blockchain</p>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                                                <span>Ethereum</span>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                                                <p>Metadata</p>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                                                <span>Centralized</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 Description pl-3 pr-3">
                                <h5>Description:</h5>
                                <p>

                                    {details.description != null ? details.description : null}
                                </p>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 price-history">
                                <ul className="nav border-bottom mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item cool-link active mr-4">
                                        <a className="nav-link" id="table-tab" data-toggle="pill" href="#table"
                                           role="tab" aria-controls="table" aria-selected="true">Trading History</a>
                                    </li>
                                    {/* <li className="nav-item cool-link active mr-4">
                                        <a className="nav-link" id="price-tab" data-toggle="pill" href="#price"
                                           role="tab" aria-controls="price" aria-selected="true">Price History</a>
                                    </li>
                                    <li className="nav-item cool-link mr-4">
                                        <a className="nav-link" id="offers-tab" data-toggle="pill" href="#offers"
                                           role="tab" aria-controls="offers" aria-selected="false">Offers History</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane trading fade show active" id="table" role="tabpanel"
                                         aria-labelledby="table-tab">
                                        {/* <select className="form-control">
                                            <option>Filters</option>
                                            <option>Filters</option>
                                        </select> */}
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Event</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">From</th>
                                                <th scope="col">To</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Hash</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.orders != undefined && this.state.orders.length > 0 && this.state.orders != {} ? this.state.orders.map(item =>

// console.log("aqeel:",item.current_price)
                                                    (
                                                        <tr>
                                                           <td>{details.creator != null && details.creator.address==item.maker.address ?
                                                            "List"
                                                            :"Offer"} </td>
                                                            <td className="align-adjust"><img
                                                                src="./assets/Images/NTFDetails/Icon awesome-ethereum.png"
                                                                className="img-fluid mr-2"/>
                                                                {item.current_price / 10 ** 18}</td>
                                                            <td>{item.quantity}</td>
                                                            <td className="align-adjust"><img
                                                                src="./assets/Images/NTFDetails/Group 37.png"
                                                                className="img-fluid mr-2"/>{item.maker.address!=null?item.maker.address.slice(0, 9).concat('...'):null}</td>
                                                            <td>{item.taker.address!=null?item.taker.address.slice(0, 9).concat('...'):null}</td>
                                                            <td>{item.created_date}</td>  
                                                            <td>--</td>
                                                        </tr>


                                                    ))


                                                :
                                                null
                                            }
                                            {/* <tr>
                          <td>Last Sale</td>
                          <td>{details.last_sale!=null?details.last_sale.total_price/10**18:null}</td>
                          <td>{details.last_sale!=null?details.last_sale.quantity:null}</td>
                          <td className="align-adjust"><img src="./assets/Images/NTFDetails/Group 37.png" className="img-fluid mr-2" />{details.last_sale!=null?details.last_sale.transaction.from_account.user.username:null}</td>
                          <td>{details.last_sale!=null?details.last_sale.transaction.to_account.user.username:null}</td>
                          <td>{details.last_sale!=null?details.last_sale.created_date:null} </td>
                          <td onClick={() => {
                            navigator.clipboard.writeText(details.last_sale.transaction.transaction_hash)
                            { ReactTooltip.hide(this.fooRef) }
                          }}
                                          >{details.last_sale!=null?this.text_truncate(details.last_sale.transaction.transaction_hash):null}</td>
                                          <ReactTooltip />
                        </tr> */}
                                            <tr>
                                                <td>Minted</td>
                                                <td className="align-adjust"><img
                                                    src="./assets/Images/NTFDetails/Icon awesome-ethereum.png"
                                                    className="img-fluid mr-2"/>
                                                </td>
                                                <td>{totalCnt}</td>
                                                <td className="align-adjust"><img
                                                    src="./assets/Images/NTFDetails/Group 37.png"
                                                    className="img-fluid mr-2"/>NullAddress
                                                </td>
                                                <td>{details.creator != null ? details.creator.address.slice(0, 9).concat('...') : null}</td>
                                                <td>{details.collection != null ? details.collection.created_date : null}</td>
                                                <td>--</td>
                                            </tr>


                                            </tbody>


                                        </table>
                                    </div>
                                    {/* <div className="tab-pane price-tab fade show active" id="price" role="tabpanel"
                                         aria-labelledby="price-tab">
                                        <select className="form-control">
                                            <option>All Times</option>
                                            <option>All Times</option>
                                        </select>
                                        <div id="container" style={{height: '300px', width: '100%'}}/>
                                        <h6>All Time Avg. Price</h6>
                                        <p><img src="./assets/Images/NTFDetails/Icon awesome-ethereum.png"
                                                className="img-fluid mr-2"/>
                                            0.03
                                        </p>
                                    </div> */}
                                    <div className="tab-pane offers-tab fade" id="offers" role="tabpanel"
                                         aria-labelledby="offers-tab">
                                        {/* 1st list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@tina</b> made an offer of $90.00
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                        {/* 2nd list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@youig</b> made an offer from of $70.50
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                        {/* 3rd list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@tina</b> made an offer of $89.09
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                        {/* 4th list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@maria</b> made an offer of $90.00
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                        {/* 5th list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@youig</b> made an offer from of $70.50
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                        {/* 6th list */}
                                        {/* <div className="row mt-3">
                                            <div
                                                className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 no-padding text-center">
                                                <img src="./assets/Images/NTFDetails/Ellipse 30.png"
                                                     className="img-fluid"/>
                                            </div>
                                            <div
                                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 text-left no-padding">
                                                <p><b>@youig</b>made an offer of $89.09
                                                </p>
                                                <span>11 MINUTES AGO [view]</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Header Section End*/}
                {/* Featured Section */}
                <section className="Featured-section pt-0 Marketplace-featured container">
                    {/* <div className="row mt-5 NTFS-featured">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <h2> More Artwork </h2>
                            <p> By Reagent </p>
                        </div>
                    </div> */}
                    <div className="row">
                        {/* 1st Card */}
                        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
                            <div className="card border-0">
                                <div className="card-body no-padding">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                                            <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                                            <p><b>@c34</b> made an offer for about
                                                $795 on <b>Lost In Stereo</b>
                                            </p>
                                            <span>11 MINUTES AGO [view]</span>
                                        </div>
                                    </div>
                                </div>
                                <img src="./assets/Images/HomeScreen/Rectangle 160.png"
                                     className="img-fluid card-img-bottom"/>
                            </div>
                        </div> */}
                        {/* 2nd Card */}
                        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
                            <div className="card border-0">
                                <div className="card-body no-padding">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                                            <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                                            <p><b>@c34</b> PAPER BOY by <b>Yiliang
                                                Yang
                                            </b>
                                            </p>
                                            <span>11 MINUTES AGO [view]</span>
                                        </div>
                                    </div>
                                </div>
                                <img src="./assets/Images/HomeScreen/Rectangle 157.png"
                                     className="img-fluid card-img-bottom"/>
                            </div>
                        </div> */}
                        {/* 3rd Card */}
                        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
                            <div className="card border-0">
                                <div className="card-body no-padding">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                                            <img src="./assets/Images/HomeScreen/Ellipse 11.png" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                                            <p><b>@c34</b> made an offer for about
                                                $795 on <b>Lost In Stereo</b>
                                            </p>
                                            <span>11 MINUTES AGO [view]</span>
                                        </div>
                                    </div>
                                </div>
                                <img src="./assets/Images/HomeScreen/Rectangle 158.png"
                                     className="img-fluid card-img-bottom"/>
                            </div>
                        </div> */}
                        {/* 4th Card */}
                        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center mt-5">
                            <div className="card border-0">
                                <div className="card-body no-padding">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                                            <img src="./assets/Images/HomeScreen/Ellipse 30.png" className="img-fluid"/>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                                            <p><b>@c34</b> made an offer for about
                                                $795 on <b>Lost In Stereo</b>
                                            </p>
                                            <span>11 MINUTES AGO [view]</span>
                                        </div>
                                    </div>ALL TIME AVG
                                    
                                </div>
                                <img src="./assets/Images/HomeScreen/Rectangle 222.png"
                                     className="img-fluid card-img-bottom"/>
                            </div>
                        </div> */}
                    </div>
                </section>
               
               
                <div className="modal fade" id="ModalMakeOffer" tabIndex="-1" aria-labelledby="ModalMakeOfferLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalMakeOfferLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className={'form-group'}>
                                        <label>Enter Offer Value</label>

                                        <input id="send-amount" type="number" placeholder="0" className="form-control" name="send-amount" value={ this.state.send_amount} onChange={this.SendAmountChange} />



                                        {/* <input type={'text'} className={'form-control'} placeholder={'Enter text'}/> */}
                                    </div>
                                    {/* <div className={'form-group'}>
                                        <label>Any Field</label>
                                        <input type={'text'} className={'form-control'} placeholder={'Enter text'}/>
                                    </div> */}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.clickMakeOffer()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ModalSell" tabIndex="-1" aria-labelledby="ModalSellLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalSellLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <form>
                                    <div className={'form-group'}>
                                        <label>Enter Sell Value</label>

                                        <input id="sell-amount" type="number" placeholder="0" className="form-control" name="sell-amount" value={ this.state.sell_amount} onChange={this.SellAmountChange} />



                                        {/* <input type={'text'} className={'form-control'} placeholder={'Enter text'}/> */}
                                    </div>
                                    {/* <div className={'form-group'}>
                                        <label>Any Field</label>
                                        <input type={'text'} className={'form-control'} placeholder={'Enter text'}/>
                                    </div> */}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary"  data-dismiss="modal"  onClick={() => this.clickSellOffer()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                </body>

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    // eth_history: state.historyStore.eth_history,
    collection_data: state.collectionStore.collection_data,
    asset_data: state.collectionStore.asset_data,
    purchasedHash: state.collectionStore.purchasdNFTHash
});


export default connect(mapStateToProps, {
    PurchaseNFT,
    BuyOffer,
    CreateSellOffer
})(
    NftDetail
);




 