import React from "react"
import Footer from './footer';
import TermConditionsHeader from './Header-banners/TermCondition';
import Navigation from './navigation';
import setAddress from '../utils/MetamaskInject'

class TermCondition extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}
componentDidMount = async ()=> {
  const add = await setAddress()
  if(add){
   this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 


componentWillMount =  async ()=> {
  const add = await setAddress()
  if(add){
      this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 
	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
			<>
            <body>
                <Navigation/>
                <TermConditionsHeader/>

                <section className="container terms">
        <p>
          The following terms and conditions apply to all website development / design
          services provided by Manchester Digital Design to the Client.
        </p>
        <h3>
          1. Acceptance
        </h3>
        <p>
          It is not necessary for any Client to have signed an acceptance of these terms and conditions for them to apply.
          If a Client accepts a quote then the Client
          will be deemed to have satisfied themselves as to the terms applying and have accepted these terms and conditions
          in full.
          Please read these terms and conditions carefully. Any purchase or use of our services implies that you have read
          and accepted our terms and conditions.
        </p>
        <h3>
          2. Charges
        </h3>
        <p>
          Charges for services to be provided by Manchester Digital Design are defined in the project quotation that the
          Client receives via e-mail. Quotations are valid
          for a period of 30 days. Manchester Digital Design reserves the right to alter or decline to provide a quotation
          after expiry of the 30 days.
          <br />
          <br />
          Unless agreed otherwise with the Client, all website design services require an advance payment of a minimum of
          thirty three (33) percent of the project
          quotation total before the work is supplied to the Client for review. A second charge of thirty three (33) percent
          is required after the development stage, with
          the remaining thirty three (33) percent of the project quotation total due upon completion of the work, prior to
          upload to the server or release of materials.
          Payment for services is due by bank transfer. Bank details will be made available on invoices.
        </p>
        <h3>
          3. Client Review
        </h3>
        <p>
          Manchester Digital Design will provide the Client with an opportunity to review the appearance and content of the
          website during the design phase and once
          the overall website development is completed. At the completion of the project, such materials will be deemed to
          be accepted and approved unless the Client
          notifies Manchester Digital Design otherwise within ten (10) days of the date the materials are made available to
          the Client.
        </p>
        <h3>
          4. Turnaround Time and Content Control
        </h3>
        <p>
          Manchester Digital Design will install and publicly post or supply the Client’s website by the date specified in
          the project proposal, or at date agreed with
          Client upon Manchester Digital Design receiving initial payment, unless a delay is specifically requested by the
          Client and agreed by Manchester Digital Design
          <br />
          <br />
          In return, the Client agrees to delegate a single individual as a primary contact to aid Manchester Digital Design
          with progressing the commission in a
          satisfactory and expedient manner. During the project, Manchester Digital Design will require the Client to
          provide website content; text, images, movies
          and sound files
        </p>
        <h3>
          5. Failure to provide required website content
        </h3>
        <p>
          Manchester Digital Design is a small business, to remain efficient we must ensure that work we have programmed is
          carried out at the scheduled time. On
          occasions we may have to reject offers for other work and enquiries to ensure that your work is completed at the
          time arranged.
          <br />
          <br />
          This is why we ask that you provide all the required information in advance. On any occasion where progress cannot
          be made with your website because we
          have not been given the required information in the agreed time frame, and we are delayed as result, we reserve
          the right to impose a surcharge of up to 25%.
          <br />
          <br />
          If you agree to provide us with the required information and subsequently fail to do within one week of project
          commencement we reserve the right to close
          the project and the balance remaining becomes payable immediately. Simply put, all the above condition says is do
          not give us the go ahead to start until you
          are ready to do so.
          <br />
          <br />
          NOTE: Text content should be delivered as a Microsoft Word, email (or similar) document with the pages in the
          supplied document representing the content of
          the relevant pages on your website. These pages should have the same titles as the agreed website pages. Contact
          us if you need clarification on this.
        </p>
        <h3>
          6. Payment
        </h3>
        <p>
          Invoices will be provided by Manchester Digital Design upon completion but before publishing the live website.
          Invoices are normally sent via email; however,
          the Client may choose to receive hard copy invoices. Invoices are due upon receipt. Accounts that remain unpaid
          thirty (30) days after the date of the invoice
          will be assessed a service charge in the amount of the higher of one and one-half percent (1.5%) or £30 per month
          of the total amount due.
        </p>
        <h3>
          7. Additional Expenses
        </h3>
        <p>
          Client agrees to reimburse Manchester Digital Design for any additional expenses necessary for the completion of
          the work. Examples would be purchase
          of special fonts, stock photography etc.
        </p>
        <h3>
          8. Web Browsers
        </h3>
        <p>
          Manchester Digital Design makes every effort to ensure websites are designed to be viewed by the majority of
          visitors. Websites are designed to work with
          the most popular current browsers (e.g. Firefox, Internet Explorer 8 &amp; 9, Google Chrome, etc.). Client agrees that
          Manchester Digital Design cannot guarantee
          correct functionality with all browser software across different operating systems.
          <br />
          <br />
          Manchester Digital Design cannot accept responsibility for web pages which do not display acceptably in new
          versions of browsers released after the website
          have been designed and handed over to the Client. As such, Manchester Digital Design reserves the right to quote
          for any work involved in changing the
          website design or website code for it to work with updated browser software.
        </p>
        <h3>
          9. Default
        </h3>
        <p>
          Accounts unpaid thirty (30) days after the date of invoice will be considered in default. If the Client in default
          maintains any information or files on Manchester
          Digital Design’s Web space, Manchester Digital Design will, at its discretion, remove all such material from its
          web space. Manchester Digital Design is not
          responsible for any loss of data incurred due to the removal of the service. Removal of such material does not
          relieve the Client of the obligation to pay any
          outstanding charges assessed to the Client’s account. Cheques returned for insufficient funds will be assessed a
          return charge of £25 and the Client’s account
          will immediately be considered to be in default until full payment is received. Clients with accounts in default
          agree to pay Manchester Digital Design reasonable
          expenses, including legal fees and costs for collection by third-party agencies, incurred by Manchester Digital
          Design in enforcing these Terms and Conditions.
        </p>
        <h3>
          10. Termination
        </h3>
        <p>
          Termination of services by the Client must be requested in a written notice and will be effective on receipt of
          such notice. E-mail or telephone requests for
          termination of services will not be honoured until and unless confirmed in writing. The Client will be invoiced
          for work completed to the date of first notice of
          cancellation for payment in full within thirty (30) days.
        </p>
        <h3>
          11. Indemnity
        </h3>
        <p>
          All Manchester Digital Design services may be used for lawful purposes only. You agree to indemnify and hold
          Manchester Digital Design harmless from any
          claims resulting from your use of our service that damages you or any other party.
        </p>
        <h3>
          12. Copyright
        </h3>
        <p>
          The Client retains the copyright to data, files and graphic logos provided by the Client, and grants Manchester
          Digital Design the rights to publish and
          use such material. The Client must obtain permission and rights to use any information or files that are
          copyrighted by a third party. The Client is further
          responsible for granting Manchester Digital Design permission and rights for use of the same and agrees to
          indemnify and hold harmless Manchester
          Digital Design from any and all claims resulting from the Client’s negligence or inability to obtain proper
          copyright permissions. A contract for website
          design and/or placement shall be regarded as a guarantee by the Client to Manchester Digital Design that all such
          permissions and authorities have been
          obtained. Evidence of permissions and authorities may be requested.
        </p>
        <h3>
          13. Standard Media Delivery
        </h3>
        <p>
          Unless otherwise specified in the project quotation, this Agreement assumes that any text will be provided by the
          Client in electronic format and that all
          photographs and other graphics will be provided physically in high quality print suitable for scanning or
          electronically in .gif, .jpeg, .png or .tiff format.
          Although every reasonable attempt shall be made by Manchester Digital Design to return to the Client any images or
          printed material provided for use in
          creation of the Client’s website, such return cannot be guaranteed.
        </p>
        <h3>
          14. Design Credit
        </h3>
        <p>
          A link to Manchester Digital Design will appear in either small type or by a small graphic at the bottom of the
          Client’s website. If a graphic is used, it will be
          designed to fit in with the overall site design. The Client also agrees that the website developed for the Client
          may be presented in Manchester Digital
          Design’s portfolio.
        </p>
        <h3>
          15. Access Requirements
        </h3>
        <p>
          If the Client’s website is to be installed on a third-party server, Manchester Digital Design must be granted
          temporary read/write access to the Client’s
          storage directories which must be accessible via FTP. Depending on the specific nature of the project, other
          resources might also need to be configured
          on the server.
        </p>
        <h3>
          16. Post-Placement Alterations
        </h3>
        <p>
          Manchester Digital Design cannot accept responsibility for any alterations caused by a third party occurring to
          the Client’s pages once installed. Such
          alterations include, but are not limited to additions, modifications or deletions.
        </p>
        <h3>
          17. Domain Names
        </h3>
        <p>
          Manchester Digital Design may purchase domain names on behalf of the Client. Payment and renewal of those domain
          names is the responsibility of the
          Client. The loss, cancellation or otherwise of the domain brought about by non or late payment is not the
          responsibility of Manchester Digital Design. The
          Client should keep a record of the due dates for payment to ensure that payment is received in good time.
        </p>
        <h3>
          18. General
        </h3>
        <p>
          These Terms and Conditions supersede all previous representations, understandings or agreements. The Client’s
          signature or payment of an advance fee
          constitutes agreement to and acceptance of these Terms and Conditions. Payment online is an acceptance of our
          terms and conditions.
        </p>
        <h3>
          19. Social Media Management
        </h3>
        <p>
          Social Media Marketing and Management is defined as helping a client to promote their products or services through
          social media channels. Manchester
          Digital Design will honour the components of your chosen social media package, providing an agreement to a minimum
          3 months contract is served and
          monthly payments are received in advance. In the event that payment is not received on time, we regret that
          further work will be halted until this is rectified.
        </p>
        <h3>
          20. Governing Law
        </h3>
        <p>
          This Agreement shall be governed by English Law.
        </p>
        <h3>
          21. Liability
        </h3>
        <p>
          Manchester Digital Design hereby excludes itself, its Employees and or Agents from all and any liability from:
          <br />
          <br />
        </p><ul>
          <li> Loss or damage caused by any inaccuracy; </li>
          <li> Loss or damage caused by omission; </li>
          <li>
            Loss or damage caused by delay or error, whether the result of negligence or other cause in the production of
            the web site;
          </li>
          <li>
            Loss or damage to clients’ artwork/photos, supplied for the site. Immaterial whether the loss or damage results
            from negligence or otherwise.
          </li>
        </ul>
        <br />
        <br />
        The entire liability of Manchester Digital Design to the Client in respect of any claim whatsoever or breach of this
        Agreement, whether or not arising out of
        negligence, shall be limited to the charges paid for the Services under this Agreement in respect of which the
        breach has arisen.
        <p />
      </section>

  
      <Footer />
</body>

    </>
    )
	}
}

export default TermCondition
