import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/css/custom.css';
// import './assets/css/bootstrap.min.css';
import HomeFeatured from './components/Home';
import dashboard from './components/dashboard'
// import zap from './components/zap'
 import loader from './components/loader/loader'
// import header from './components/header'
// import earn from './components/earn'


import Signup from './components/auth/signup'
import Login from './components/auth/login'
import installMetamask from './components/auth/installMetamask'
import NoMetaMask from './components/auth/noMetamask'
import ContactUs from './components/ContactUs'
import AccountSetting from './components/AccountSetting'
import TermCondition from './components/Terms&Condition'
import Reward from './components/Reward'

import CreatorSubmission from './components/CreatorSubmission'
import NftDetail from './components/NftDetails'

import Home from './components/Home1';


import AccountCollections from './components/AccountCollections';
import MarketPlace from './components/MarketPlace';
import FeaturedView from './components/featuredView';
import Physicalstore from './components/Physicalstore';



import * as serviceWorker from './serviceWorker';
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";







ReactDOM.render(
  <Provider store={store}>
 
 <BrowserRouter>
 <Switch>

 <Route path="/login" component={Login}  />

 {/* <Route path="/" component={Login}  /> */}

      <Route path="/Home" exact component={Home} />
  
      <Route path="/dashboard" component={dashboard} isPrivate />
      {/* <Route component={loader}/> */}
      {/* <Route path="/platform" component={header}  />
      <Route path="/earn" component={earn}  /> */}

      <Route path="/signup" component={Signup}  />
    
      <Route path="/installMetamask" component={installMetamask}  />
      <Route path="/noMetaMask" component={NoMetaMask}  />
      <Route path="/contactUs" component={ContactUs}  />
      <Route path="/accountSetting" component={AccountSetting}  />
      <Route path="/term&Condition" component={TermCondition}  />
      <Route path="/reward" component={Reward}  />
      <Route path="/nftDetail" component={NftDetail}  />

      <Route path="/HomeFeatured" exact component={HomeFeatured}  />


      <Route path="/creatorSubmission" component={CreatorSubmission}  />
      





      <Route path="/accountCollections" component={AccountCollections}  />
      <Route path="/marketPlace:collectionName" component={MarketPlace}  />
      <Route path="/physicalStore" component={Physicalstore}  />

      <Route path="/featuredView" component={FeaturedView}  />


      
      <Route path="/" component={Login}  />

      



    </Switch>
    </BrowserRouter>

  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
 serviceWorker.unregister();
