import React from "react"
import Footer from './footer';
import AccountSettingHeader from './Header-banners/AccountSetting';
import Navigation from './navigation';
import setAddress from '../utils/MetamaskInject'

class AccountSetting extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}


componentDidMount = async ()=> {
  const add = await setAddress()
  if(add){
   this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 


componentWillMount =  async ()=> {
  const add = await setAddress()
  if(add){
      this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 
	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
			<>
            <body>
                <Navigation/>
                <AccountSettingHeader/>

                <section className="Account-section container">
        <div className="row">
          {/* User Profile Section */}
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 a-side">
            <div className="account-detail">
              <div className="text-center">
                <img src="./assets/Images/Rewards/Group 91.png" className="img-fluid" alt="reward" />
              </div>
              <h5>UserName</h5>
              <h3>crazybird96</h3>
              <h5>Bio</h5>
              <p>Rerum est laudantium ut soluta eos voluptatem et quidem. Sit
                eum eaque praesentium possimus est. Aut enim perferendis per
                spiciatis qui neque aut provident.</p>
              <h5>Email</h5>
              <p>crazybird96@gmail.com</p>
            </div>
            <div className="wallet">
              <h5>Wallet Address</h5>
              <p>0x6146ccf6a66d994f7c363db875e31ca3558 <i className="far fa-clone" /></p>
              <h4>Minimum Bid Threshold</h4>
              <div className="input-group dropdown">
                <a className="dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="./assets/Images/NTFDetails/Icon awesome-ethereum.png" className="img-fluid mr-2" alt="UE" />ETH
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="javascript:void(0)">
                    <img className="img-fluid" src="./Assets/Images/NTFDetails/Icon awesome-ethereum.png" alt="UE" /> ETH
                  </a>
                  <a className="dropdown-item" href="javascript:void(0)"><img className="img-fluid" src="./Assets/Images/NTFDetails/Icon awesome-ethereum.png" alt="UE" /> EN</a>
                </div>
                <div className="input-group-append">
                  <input type="number" className="form-control" placeholder="0.05" />
                </div>
              </div>
            </div>
          </div>
          {/* Basic Profile Section */}
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 b-side">
            <div className="profile">
              <h4>Basic Profile</h4>
              <form>
                <div className="row mt-4">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Username" />
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Email Address" />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <textarea className="form-control" placeholder="Bio" rows={3} defaultValue={""} />
                  </div>
                </div>
              </form>
            </div>
            <div className="notifications">
              <h4>Notification Settings</h4>
              <form className="listings">
                {/* Item 1 */}
                <div className="items border-top">
                  <div>
                    <input type="checkbox" id="item1" />
                    <label htmlFor="item1" />
                  </div>
                  <div>
                    <h5>Item Sold</h5>
                    <p>When someone purchased one of your items</p>
                  </div>
                </div>
                {/* Item 2 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item2" />
                    <label htmlFor="item2" />
                  </div>
                  <div>
                    <h5>Bid Activity</h5>
                    <p>When someone bids on one of your items</p>
                  </div>
                </div>
                {/* Item 3 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item3" />
                    <label htmlFor="item3" />
                  </div>
                  <div>
                    <h5>Price Change</h5>
                    <p>When an item you made an offer on changes in price</p>
                  </div>
                </div>
                {/* Item 4 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item4" />
                    <label htmlFor="item4" />
                  </div>
                  <div>
                    <h5>Auction Expiration</h5>
                    <p>When a Dutch or English auction you created ends</p>
                  </div>
                </div>
                {/* Item 5 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item5" />
                    <label htmlFor="item5" />
                  </div>
                  <div>
                    <h5>Outbid</h5>
                    <p>When an offer you placed is exceeded by another user</p>
                  </div>
                </div>
                {/* Item 6 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item6" />
                    <label htmlFor="item6" />
                  </div>
                  <div>
                    <h5>Referral Successful</h5>
                    <p>When an item you referred is purchased</p>
                  </div>
                </div>
                {/* Item 7 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item7" />
                    <label htmlFor="item7" />
                  </div>
                  <div>
                    <h5>Owned Asset Updates</h5>
                    <p>When a significant update occurs for one of the items you have purchased on opensea</p>
                  </div>
                </div>
                {/* Item 8 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item8" />
                    <label htmlFor="item8" />
                  </div>
                  <div>
                    <h5>Successful Purchase</h5>
                    <p>When you successfully buy an item</p>
                  </div>
                </div>
                {/* Item 9 */}
                <div className="items">
                  <div>
                    <input type="checkbox" id="item9" />
                    <label htmlFor="item9" />
                  </div>
                  <div>
                    <h5>OpenSea Newsletter</h5>
                    <p>Occasional updates from the OpenSea team</p>
                  </div>
                </div>
                <button type="button" href="javascript:void(0)" className="mt-4">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
</body>

    </>
    )
	}
}

export default AccountSetting
