const authActionTypes = {
   
    GET_COLLECTION_DATA: "GET_COLLECTION_DATA",
    GET_ALLCOLLECTION_NAME: "GET_ALLCOLLECTION_NAME",
    GET_ASSET_DATA: "GET_ASSET_DATA",
    PURCHASED_NFT: "PURCHASED_NFT",
    GET_PHYSICAL_DATA:"GET_PHYSICAL_DATA"







}

export default authActionTypes;