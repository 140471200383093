import React, { Component } from 'react'
import { connect } from 'react-redux';
import {getAllCollections,getSpecificCollection} from '../services/collection/action'

import {useHistory} from "react-router-dom"

class Sidebar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      collName:'',
      search: "",

    }

   // console.log("inside sidebar:",props.collectionNames)
  // this.props.getAllCollections()
   this.onSearch = this.onSearch.bind(this)
  //  const history = useHistory()

  }

  async onSearch(param) {
    

   // alert("seract"+ param)
    this.props.getSpecificCollection(param)
    console.log("param",param)
    // await history.replace(`/marketPlace:dicd`)

   }
   
  componentDidMount = async ()=> {
    this.props.getAllCollections()
    console.log("componentDidMount:",this.props.allCollection_name)
  this.setState({collName:this.props.allCollection_name})
  console.log("i am sidebar",this.state.collName)

  }; 


  async componentWillReceiveProps(nextProps) {
  //  this.props.getAllCollections()
    console.log("componentWillReceiveProps:",nextProps.allCollection_name)

 this.setState({
  collName:nextProps.allCollection_name
    });
    console.log("i am sidebar",this.state.collName)
  }




  closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }


	render() {

    console.log("i am sidebar in render",this.state.collName)

		return (
			<>
   {/* {this.props.allCollection_name.map(item=>{

console.log("aqeel:",item.name)
})} */}
<div className="position-relative">
        <div id="mySidenav" className="sidenav shadow">
          <div className="d-flex">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              {/* <div className="has-search">
                <span className="fa fa-search form-control-feedback" />
                <input type="text" className="form-control" placeholder="Search here" />
              </div> */}
            </div>
          </div>
          <div className="d-flex mt-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex">
              <a href="http://32karateesthetix.online/#" className="product-btn pr-1" onClick={()=>'http://32karateesthetix.online/#'}  target="_blank">
                <img src="./assets/Images/MarketPlace/physical.png" className="img-fluid" />
                {/* <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 p-2">
            <img src="./assets/Images/MarketPlace/Icon awesome-boxes.png" class="img-fluid" alt="icon-box">
          </div>
          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 p-2">
            <span> Physical</span>
            <span>Products</span>
          </div> */}
              </a>
              <a href="javascript:void(0)" className="digital-btn">
                <img src="./assets/Images/MarketPlace/digital.png" className="img-fluid" />
              </a>
            </div>
          </div>
          {/* Collections Section */}
          <div className="collections-view mt-5">
            <div className="d-flex">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 justify-content-between d-flex">
                <h4>Collections</h4>
                {/* <a href="#">View More</a> */}
              </div>
            </div>
       


    
      
       
          <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a href="#" onClick= {() => this.onSearch('paper-boy')}> <h3>Paper Boy </h3>   </a>
             <i className="fas fa-times" />
           </div>
           </div>
         
           <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a href="#"  onClick= {() => this.onSearch('tim-beckhardt')}><h3>CAINUS—Newferatu </h3> </a>
             <i className="fas fa-times" />
           </div>
           </div>
           <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a  href="#" onClick= {() => this.onSearch('the-last-of-its-kind')}> <h3>The Last Vending Machine </h3> </a>
             <i className="fas fa-times" />
           </div>
           </div>
           <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a  href="#" onClick= {() => this.onSearch('pixelbart')}><h3>Bart's Pixel Taipei 《巴特的像素台北》 </h3> </a>
             <i className="fas fa-times" />
           </div>
           </div>
           <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a  href="#" onClick= {() => this.onSearch('sandy')}> <h3>《Sandy's Drop》 Sandy Cheeks X Wilfred Y. II </h3> </a>
             <i className="fas fa-times" />
           </div>
           </div>


           {/* <div className="d-flex mt-2">
           <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
             <i className="fas fa-check-circle" />
           </div>
           <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
           <a  href="#" onClick= {() => this.onSearch('untitled-collection-2348219')}> <h3>Dice </h3> </a>
             <i className="fas fa-times" />
           </div>
           </div> */}
         
{/* {this.props.allCollection_name[0]?} */}
{/* {this.props.allCollection_name[0]?
              
              this.props.allCollection_name.map(item=>{
                return(
              

                )
              })
              
              
              :null} */}
         

            {/* <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <img src="./assets/Images/MarketPlace/Rectangle 199.png" className="img-fluid" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Paper Boy</h3>
              </div>
            </div> */}
            {/* <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <img src="./assets/Images/MarketPlace/Rectangle 200.png" className="img-fluid" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Lost in Stereo</h3>
              </div>
            </div> */}
          </div>
          {/* Categories Section */}
          {/* <div className="collections-view mt-5">
            <div className="d-flex">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 justify-content-between d-flex">
                <h4>Categories</h4>
                <a href="#">View More</a>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <i className="fas fa-check-circle" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Digital Art</h3>
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <i className="fas fa-check-circle" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Physical Products</h3>
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <img src="./assets/Images/MarketPlace/Rectangle 199.png" className="img-fluid" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Motion Graphics</h3>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <img src="./assets/Images/MarketPlace/Rectangle 200.png" className="img-fluid" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Music</h3>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                <img src="./assets/Images/MarketPlace/Rectangle 206.png" className="img-fluid" />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 justify-content-between d-flex pl-0 align-items-center">
                <h3>Animation</h3>
              </div>
            </div>
          </div>
        
         */}
        
          {/* DropDown Section */}
          {/* <div className="collections-view mt-5">
            <div className="d-flex">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <h4>Price</h4>
                <select className="form-control mt-2">
                  <option>USDT</option>
                  <option>USDT</option>
                </select> */}
                {/* Range Section */}
                {/* <div className="d-flex justify-content-center my-4">
                  <span className="min">MIN</span>
                  <form className="range-field w-100">
                    <input className="border-0 w-100" type="range" min={0} max={5000} />
                  </form>
                  <span className="max">MAX</span>
                </div> */}
                {/* <input type="text" id="demo_0" name="my_range" value="" data-type="double" data-grid="true" /> */}
              {/* </div>
            </div>
          </div> */}
          {/* Buttons Section */}
          <div className="buttons-view pt-4 pb-4 border-top">
            <div className="d-flex">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex">
                <button type="button" className="apply" onClick= {() => this.closeNav()}>Close</button>
                {/* <button type="button" className="apply" onClick= {() => this.closeNav()}>Apply</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
   	</>
          
		)
	}
}

const mapStateToProps = (state) => ({
  allCollection_name: state.collectionStore.allCollection_name,
  collection_data: state.collectionStore.collection_data,

  });
  
export default connect(mapStateToProps, {
  
  getAllCollections,getSpecificCollection    
})(
  Sidebar
);

