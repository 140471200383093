import React, { Component } from 'react'
import { connect } from 'react-redux';
import MarketPlaceHeader from './Header-banners/MarketPlace'
import MarketPlaceSearch from './Header-banners/MarketPlaceSearch'
import Sidebar from './sidebar1';

import Navigation from './navigation'
import Footer from './footer';

import setAddress from '../utils/MetamaskInject'
import {getCurrentUserCollection,getAllCollections,getSpecificAsset} from '../services/collection/action'
const JSJoda = require('js-joda');
const LocalDate = JSJoda.LocalDate;
class FeaturedView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:"",
      collectionData:"",
      collectionName:""
    }

    const add =  setAddress()
  
    if(add){
   //   this.props.history.push("/login")
// alert("addres:",add)
    this.setState({loggedAddress:add})
    this.props.getCurrentUserCollection('pixelbart',add)

    }
    else{
    
     this.props.history.push("/login")
    
    }
  //  this.props.getAllCollections()
    this.searchAsset=this.searchAsset.bind(this)
 
   
}
  componentDidMount = async ()=> {
    const add = await setAddress()
    if(add){
     // this.props.history.push("/login")
     this.setState({loggedAddress:add})
    }
    else{
      this.props.history.push("/login")
    }
    
    //this.props.getSpecificCollection('emperorofchina')

  //   if(this.props.collection_data){

    this.setState({collectionData:this.props.collection_data
    //  ,collectionName:this.props.allCollection_name
    })
    //  console.log("this.props.collection_data:",this.props.collection_data)

   //  }
   
    
  }; 


  componentWillMount =  async ()=> {
    const add = await setAddress()
    if(add){
    //  this.props.history.push("/login")
        this.setState({loggedAddress:add})
    }
    else{
      this.props.history.push("/login")
    }
    
    //if(this.props.collection_data){

    //  this.setState({collectionData:this.props.collection_data})
   //   console.log("this.props.collection_data:",this.props.collection_data)

     //}
    
  }; 






  async componentWillReceiveProps(nextProps) {
   // console.log("collectionData:", nextProps.collection_data);
    // var 
    // nextProps.collection_data.map(info=>{

    // })
 

    this.setState({
      collectionData: nextProps.collection_data,
    //  collectionName:nextProps.allCollection_name
    });
    // this.setState({ result: object });
  }


  openNav() {
    document.getElementById("mySidenav").style.width = "260px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }


  searchAsset(data){
// console.log("clicked....",data)
//     alert("clicked....")
    this.props.getSpecificAsset(data.token_id)
    this.props.history.push("/nftDetail")


  }
  
   getNumberOfDays(start, end) {
    const start_date = new LocalDate.parse(start);
    const end_date = new LocalDate.parse(end);

    return JSJoda.ChronoUnit.DAYS.between(start_date, end_date);
}
	render() {
  //  console.log("data:",this.state.collectionData)

    // var d =this.state.collectionData
  //   this.state.collectionData.map(data=>{
  //   console.log("data:",this.state.collectionData)
  // })
    const add =  setAddress()
    if(add){
    //  this.props.history.push("/login")
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
  return (
      <>
      <body>
        < Navigation />
        <MarketPlaceHeader/>

        <section className="Featured-section Marketplace-featured container">
        <MarketPlaceSearch />
        <div className="row mt-5">
        {/* 1st Card */}

        {this.state.collectionData[0]?this.state.collectionData.map(data=>{
         // console.log("data:",data.sell_orders[0].current_bounty)
          return(
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 text-center">
            <div className="card border-0">
              <div className="card-body no-padding">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 no-padding">
                  {data.owner? <img  src={data.owner.profile_img_url} className="img-fluid"       /> :null}  
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left">
                    <p>       {data.owner.user.username=="NullAddress" ?  <b>Creator </b> :<b> {data.owner.user.username}  </b>}  
                      
                   
                    made an offer for about $
                      {data.sell_orders!=null ? 
                      
                      (data.sell_orders[0].current_price/10**18) * data.sell_orders[0].payment_token_contract.usd_price:`-`} on <b>{data.collection.name}</b>
                    </p>
                    <span>
                    {
                    data.sell_orders!=null && data.sell_orders[0].closing_date!=null ?
                    this.getNumberOfDays(data.sell_orders[0].created_date.split("T")[0],data.sell_orders[0].closing_date.split("T")[0])+" days left" 
                    :`-` 
                    }
                      </span>
                  </div>
                </div>
              </div>
              {/* href="./nftDetail"   target="_blank"  */}
              <a href="#"    onClick={()=>{this.searchAsset(data)}}>  <img src={data.image_url} onError={(e)=>{e.target.onerror = null; e.target.src="image_path_here"}} className="img-fluid card-img-bottom" /> </a>
            </div>
          </div>
          )
        }
          ):null}
    
    
      </div>

        </section>
       
        <Sidebar />

        <Footer/>
        </body>
        </>
    )
	}



}

const mapStateToProps = (state) => ({
// eth_history: state.historyStore.eth_history,
collection_data: state.collectionStore.collection_data,
allCollection_name: state.collectionStore.allCollection_name
});


export default connect(mapStateToProps, {
  getCurrentUserCollection,
  getAllCollections,
  getSpecificAsset,    
})(
  FeaturedView
);
