import React from "react"
import Footer from './footer';
import ContactUsHeader from './Header-banners/ContactUs';
import Navigation from './navigation';
import setAddress from '../utils/MetamaskInject'

class ContactUs extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loggedAddress:""
    }
    
    
    const add =  setAddress()
    if(add){

    this.setState({loggedAddress:add})
    }
    else{
     this.props.history.push("/login")
    
    }
   
}

componentDidMount = async ()=> {
  const add = await setAddress()
  if(add){
   this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 


componentWillMount =  async ()=> {
  const add = await setAddress()
  if(add){
      this.setState({loggedAddress:add})
  }
  else{
    this.props.history.push("/login")
  }
  
}; 
	render() {
    const add =  setAddress()
    if(add){
        console.log("checkMetaMask",add)
    }
    else{
     this.props.history.push("/login")
    }
		return (
			<>
            <body>
                <Navigation/>
                <ContactUsHeader/>

        <section className="Contact-us container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 a-side">
              <h4>what is a NFT and how can Reagent Corp help?</h4>
              <p>We can assure you Reagent Corp can be your trusted guide in the crypto universe.
                <br />
                <br />
                The value of art, any collectible, or crypto asset is determined by many different variables.
                But what is important and what has not been extensively studied is the bridge between how
                we value crypto art versus physical art. That is why we want to become that bridge.
              </p>
              <div className="contact-info mt-5">
                <i className="fas fa-phone-alt mr-4" />
                +44 1457 764 809
              </div>
              <div className="contact-info mt-3">
                <i className="fas fa-at mr-4" />
                support@reagent.com
              </div>
              <div className="contact-info mt-3">
                <i className="fas fa-map-marker-alt mr-4" />
                63 Stalyhill Drive, Stalybridge, SK15 2TT.
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 b-side">
              <h4>Contact Us </h4>
              <h5>Get in touch with us</h5>
              <form>
                <div className="row mt-4">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="First Name" />
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Last Name" />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <input type="email" className="form-control" placeholder="Email Address" />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Purpose of Contact" />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <textarea className="form-control" placeholder="Any message…" rows={4} defaultValue={""} />
                  </div>
                </div>
                <button type="button" href="javascript:void(0)" className="mt-5">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </section>
        {/* Contact Us Section End*/}
        {/* Collapse Section */}
        <section className="Collapse-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <h4>CUSCUS’S NFT PARADISE </h4>
                <h6>by <b>Cuscus the Cuckoo</b> </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <div id="accordion">
                  {/* Collapse 1 */}
                  <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Consequatur ea ut ab beatae accusamus fuga ducimus.
                      </h5>
                      <i className="fas fa-plus" />
                      <i className="fas fa-minus" />
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="card-body">
                        Saepe non deleniti. Consequatur quidem magni incidunt nemo delectus non qui. Architecto enim dolorum
                        est et natus facere nostrum nobis est. Praesentium explicabo accusamus nesciunt magnam
                        dolor iste non autem corrupti. Est esse quia dolor ipsam nemo unde. Aliquam quia amet illum. Ut
                        voluptate maxime ipsa optio. Praesentium quia distinctio rerum magnam qui dolorem consequatur
                        molestiae. Vel possimus iusto temporibus qui magnam quis. Excepturi quia placeat. Ipsam aut nam
                        repellendus ullam sit. Quaerat optio impedit magnam et.Molestiae eius eius veniam accusamus
                        eius sed. Officiis eos nulla quasi ipsa sit non. Ut optio cupiditate porro autem ut molestiae qui. Aut
                        molestiae non. Culpa voluptatem et ex pariatur nostrum voluptatem autem assumenda quidem.
                      </div>
                    </div>
                  </div>
                  {/* Collapse 2 */}
                  <div className="card">
                    <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Id sed aut.
                      </h5>
                      <i className="fas fa-plus" />
                      <i className="fas fa-minus" />
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 3 */}
                  <div className="card">
                    <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Rerum quam eligendi corrupti qui odit et.
                      </h5>
                      <i className="fas fa-plus" />
                      <i className="fas fa-minus" />
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 4 */}
                  <div className="card">
                    <div className="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Sit rerum tenetur quod nisi quas quia enim.
                      </h5>
                      <i className="fas fa-plus" />
                      <i className="fas fa-minus" />
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 5 */}
                  <div className="card">
                    <div className="card-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Non molestiae rem minima esse et reiciendis sint veritatis aut.
                      </h5>
                      <i className="fas fa-plus" />
                      <i className="fas fa-minus" />
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 6 */}
                  <div className="card">
                    <div className="card-header" id="headingSix" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Ad ipsum id eius adipisci magni qui corrupti in.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 7 */}
                  <div className="card">
                    <div className="card-header" id="headingSeven" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Eveniet nihil aut voluptas pariatur ullam deserunt ea sint earum.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 8 */}
                  <div className="card">
                    <div className="card-header" id="headingEight" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Aperiam rem ullam iure magni nisi et consequatur qui culpa.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 9 */}
                  <div className="card">
                    <div className="card-header" id="headingNine" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Eos assumenda quisquam fugit est veniam consequatur aliquam ex deleniti.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 10 */}
                  <div className="card">
                    <div className="card-header" id="headingTen" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Minus quo nemo ut dolorem quo quia illo et.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 11 */}
                  <div className="card">
                    <div className="card-header" id="headingEleven" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Recusandae ut harum commodi id doloremque voluptatem voluptate.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 12 */}
                  <div className="card">
                    <div className="card-header" id="headingTwelwe" data-toggle="collapse" data-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Dolorem quas consectetur necessitatibus.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseTwelwe" className="collapse" aria-labelledby="headingTwelwe" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 13 */}
                  <div className="card">
                    <div className="card-header" id="headingThirteen" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Expedita quas nobis quae.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 14 */}
                  <div className="card">
                    <div className="card-header" id="headingFourteen" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Voluptas unde dolorum magnam quia non et.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 15 */}
                  <div className="card">
                    <div className="card-header" id="headingFifteen" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Tempore quia quod dolor aut et.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                  {/* Collapse 16 */}
                  <div className="card">
                    <div className="card-header" id="headingSixteen" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                      <h5 className="mb-0">
                        <img src="./assets/Images/Rewards/Icon metro-question.png" className="img-fluid mr-3" />
                        Dolorem quasi ab nobis impedit sint.
                      </h5>
                      <i className="fas fa-plus" />
                    </div>
                    <div id="collapseSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordion">
                      <div className="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
      <Footer />
</body>

    </>
    )
	}
}

export default ContactUs
