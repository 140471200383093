import React from "react"
import { connect } from 'react-redux';
import {getSpecificCollection} from '../../services/collection/action'

class MarketPlaceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearch = this.onSearch.bind(this)




  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
  }
  

  openNav() {
    document.getElementById("mySidenav").style.width = "260px";
  }

  onSearch() {
   this.props.getSpecificCollection(this.state.search)
  }
  
	render() {
		return (
			<>


<div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 m-auto">
            <div className="input-group has-search">
              <span className="fa fa-search form-control-feedback" />
              <input 
                 value={this.state.search}
                 name="search"
                 onChange={this.onChange}
              type="text" className="form-control" placeholder="Search from our Huge Stock of Creative Art & Products" />
              <div className="input-group-append">
                <button type="button" onClick= {() => this.onSearch()}>
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="siderbar-logo" onClick= {() => this.openNav()}>
            <img src="./assets/Images/MarketPlace/Group 31.png" className="img-fluid search-sidebar-icon" alt="search-bar" />
          </div>
        </div>
      	</>
		)
	}
}


const mapStateToProps = (state) => ({
  // eth_history: state.historyStore.eth_history,
  collection_data: state.collectionStore.collection_data,
  });
  
  
  export default connect(mapStateToProps, {
    getSpecificCollection    
  })(
    MarketPlaceSearch
  );
  