import React from "react"
import info from '../utils/emailKey'
import emailjs from 'emailjs-com';
import "react-toastify/dist/ReactToastify.min.css";
import { Flip, toast } from "react-toastify";
class Footer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
   
      email:"",
    
       result: "",

      message: "",
    };
    
    
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
   
}


onChange(e) {
  this.setState({
    [e.target.name]: e.target.value,
  });
}


onSubmit = async (e) => {
  e.preventDefault();





  var template_params= {
    'firstname': "User",
    'email': this.state.email,
    'location': "XYZ",
    'message': "please Subscribe me..."
}


emailjs.send(info.SERVICE_ID(),info.TEMPLATE_ID(), template_params, info.USER_ID())
.then((response) => {
   console.log('SUCCESS!', response.status, response.text);
   toast.configure();
     toast("Email Sent Successfuly", {
       position: toast.POSITION.TOP_RIGHT,
       autoClose: 2000,
       toastId: 5,
     });
}, (err) => {
   console.log('FAILED...', err);
});


};

	render() {










  


		return (
			<>


<section className="Footer-section pb-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
              <img src="./assets/Images/HomeScreen/Reagent_logo.png" className="img-fluid" alt="logo" />
              <p className="detail mt-4"> We can assure you Reagent Corp can be your trusted guide in
                the crypto universe.
              </p>
              <p className="detail mt-5"> The value of art, any collectible, or crypto asset is determined
                by many different variables. But what is important and what
                has not been extensively studied is the bridge between how
                we value crypto art versus physical art. That is why we want to
                become that bridge.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 links-padding">
              <div className="height-set pl-5">
                <a href="./home">
                  Home
                </a><br />
                <a href="./marketPlace">
                  Marketplace
                </a><br />
                <a href="./myArt">
                My Art
                </a><br />
                <a href="./login">
                  Connect Wallet
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12">
              <div className="height-set">
                <a href="./term&Condition">
                  FAQs
                </a><br />
                <a href="./term&Condition">
                  Terms of Services
                </a><br />
                <a href="./term&Condition">
                  Privacy Policy
                </a><br />
                <a href="./contactUs">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <div className="height-set">
                <h3 className="pt-3"><b>Subscribe to</b>
                  our newsletter </h3>
                  <input
                          class="form-control"
                          value={this.state.email}
                          name="email"
                          onChange={this.onChange}
                          type="email"
                          placeholder="   Email Address"
                          required="required"
                        />
                <button type="button" onClick={this.onSubmit} className="mt-4">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <div className="border-top mt-5 ">
            <div className="row mt-3 justify-content-between width-adjust">
              <div className>
                <p className="footer-info"><b className="mr-2">HEADQUATER</b>
                  <span> 18007 Cortney Court, City of Industry, CA 91748</span>
                </p>
              </div>
              <div className="footer-social-icons">
                <a href="javascript:void(0)">
                  <i className="fab fa-twitter" />
                </a>
                <a href="javascript:void(0)">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="javascript:void(0)">
                  <i className="fab fa-instagram" />
                </a>
                <a href="javascript:void(0)">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="javascript:void(0)">
                  <i className="fas fa-envelope" />
                </a>
              </div>
              <div className="footer-cpyright">
                <p>Copyrights @ 2021, All rights reserved by Reagent Corp.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
    )
	}
}

export default Footer
