import authType from "./actionType";
import axios from "axios";
import Web3 from 'web3';
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import openSea from "../../utils/openseaURL"
// //Testnet_openSea
import { OrderSide } from 'opensea-js/lib/types'
import { OpenSeaPort, Network } from 'opensea-js'
var web3 = new Web3(window.ethereum);


// const MNEMONIC = '0xac8ad3be82aafe8cf2774656e5a31cf4295fe32694791b283d2a46a8ac1d4584';
// const MnemonicWalletSubprovider = require("@0x/subproviders")
//   .MnemonicWalletSubprovider;
//   const BASE_DERIVATION_PATH = `44'/60'/0'/0`;

//   const RPCSubprovider = require("web3-provider-engine/subproviders/rpc");

//   const mnemonicWalletSubprovider = new MnemonicWalletSubprovider({
//     mnemonic: MNEMONIC,
//     baseDerivationPath: BASE_DERIVATION_PATH,
//   });


//   const infuraRpcSubprovider = new RPCSubprovider({
//     rpcUrl: 'https://rinkeby.infura.io/v3/fb0239e1d6534db39d378a8f9ecf190f'
//   });


// const Web3ProviderEngine = require("web3-provider-engine");

// const providerEngine = new Web3ProviderEngine();
// providerEngine.addProvider(mnemonicWalletSubprovider);
// providerEngine.addProvider(infuraRpcSubprovider);
// providerEngine.start();


// const opensea = require("opensea-js");
// const OpenSeaPort = opensea.OpenSeaPort;
// const API_KEY = process.env.API_KEY || ""; 
// const Network = opensea.Network;
// const seaport = new OpenSeaPort(
//     providerEngine,
//     {
//       networkName: Network.Rinkeby,
//       apiKey: API_KEY,
//     },
//     (arg) => console.log(arg)
//   );


//var web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/6a4dff93883f4e6f9dc42cf63319d789");
// var  web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');
//var web3 = new Web3(Web3.givenProvider || "https://ropsten.infura.io/v3/f030c72616984ab7a70feef6007b7cd3");



// const provider = new Web3.providers.HttpProvider('https://speedy-nodes-nyc.moralis.io/2e2204ede49b10397e0df6ef/eth/rinkeby')



// const add =  setAddress()




// This example provider won't let you make transactions, only read-only calls:

// const seaport = new OpenSeaPort(provider, {
//   networkName: Network.Main
// })
// console.log("seaport:",seaport.api)
// async function ap(){
// const { orders, count } = await seaport.api.getOrders({
//     asset_contract_address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
//     token_id: '110455694732608969814569818994873767172534514500119104126523392736496227188745',
//    side: OrderSide.Sell
//   })

//   console.log("order:",orders)
//   console.log("count:",count)
// }

// ap()
// https://api.opensea.io/api/v1/assets?owner=0xa021211637ef5125f6dad95592e49db2a4ee3fe6&order_direction=desc&offset=0&limit=20&collection=emperorofchina


// * Fullfill or "take" an order for an asset, either a buy or sell order
// * @param param0 __namedParamaters Object
// * @param order The order to fulfill, a.k.a. "take"
// * @param accountAddress The taker's wallet address
// * @param recipientAddress The optional address to receive the order's item(s) or curriencies. If not specified, defaults to accountAddress.
// * @param referrerAddress The optional address that referred the order
// * @returns Transaction hash for fulfilling the order
// */
// OpenSeaPort.prototype.fulfillOrder = function (_a) {

// const fixedPricePurchaseOrder = await seaport.fulfillOrder({
//     order,
//     accountAddress, // The address of your wallet, which will sign the transaction
//     recipientAddress
//   });
//   console.log(
//     `Successfully created a fixed-price sell order! ${fixedPricePurchaseOrder.asset.openseaLink}\n`
//   );




export const BuyOffer = (address, offerAmount, tokenID) => async (dispatch) => {
    const seaport = new OpenSeaPort(web3.currentProvider, {
        networkName: Network.Mainnet,
        apiKey:'43abf245ba3d4cbb8b5c3fcc4f0fd1db'
    })


    const tokenAddress = '0x495f947276749ce646f68ac8c248420045cb7b5e'//"0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656"
    const tokenId = tokenID//'79630473148403579200232510153367869810807992404435473599666936436705676230657'
    const paymentTokenAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'// "0xc778417e063141139fce010982780140aa0cd5ab"
    const amount = offerAmount
    const accountAddress = address.toString()
    //const referrerAddress = '0xb00D4027D792A04faC532028DB1Ec6391468C138'
    await seaport.createBuyOrder({
        asset: {
            tokenId,
            tokenAddress,
            schemaName: "ERC1155"
        },
        accountAddress, // Address of the bidder
        startAmount: amount,
        paymentTokenAddress
        // referrerAddress // Address of the referrer
    })

        .then(async (transactionHash) => {
            const hash = await transactionHash.hash
            console.log("transactionHash:", hash)
            // alert("DONE:",transactionHash)
            toast.configure();
            toast.success("Offered successfuly:" + hash, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                toastId: 1,
            });
            dispatch({
                type: authType.PURCHASED_NFT,
                payload: transactionHash,
            });
            // }
        })
        .catch((err) => {
            // alert(err)
            toast.configure();
            toast.error("failed with error:" + err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                toastId: 2,
            });

        });
}


export const PurchaseNFT = (address, tokenID) => async (dispatch) => {
    const seaport = new OpenSeaPort(web3.currentProvider, {
        networkName: Network.Mainnet
        ,apiKey:'43abf245ba3d4cbb8b5c3fcc4f0fd1db' 
    })
    const tokenId = tokenID

    const order = await seaport.api.getOrder({

        asset_contract_address: '0x495f947276749ce646f68ac8c248420045cb7b5e',//"0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656",

        token_id: tokenId,
        side: OrderSide.Sell

    })

    if (order) {
        // alert("Order found:",order)
        toast.configure();
        toast.success("Order Found, we are just processing!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            toastId: 3,
        });

        const accountAddress = address // The buyer's wallet address, also the taker
        //   const transactionHash =
        await seaport.fulfillOrder({ order, accountAddress }).then(async (transactionHash) => {

            const hash = await transactionHash.hash
            console.log("transactionHash:", hash)
            // alert("DONE:",transactionHash)
            toast.configure();
            toast.success("Offered successfuly:" + hash, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                toastId: 4,
            });
            // alert("DONE:",transactionHash)
            dispatch({
                type: authType.PURCHASED_NFT,
                payload: transactionHash,
            });
            // }
        })
            .catch((err) => {
                toast.configure();
                toast.error("failed with error:" + err, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    toastId: 5,
                });

            });
    }
    else {
        // alert("Order not found")
        const err = "Order not found"
        toast.configure();
        toast.error("failed with error:" + err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            toastId: 6,
        });
    }

    //  const tokenId='79630473148403579200232510153367869810807992404435473599666936432307629719553'
    //  const tokenAddress = '0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656'
    //  const fromAddress = '0x8b7CDe4C9B374a3FE82a353d0595C712806Ef5Ec'
    //  const toAddress = '0x85769f762d8EDd1009d7Fec5987ea01e2f742533'
    //   const transactionHash = await seaport.transfer({
    //     asset: { tokenId, tokenAddress },
    //     fromAddress, // Must own the asset
    //     toAddress
    //   }).then(res=>{
    //     console.log("transactionHash1:",res)

    //   })
    // console.log("transactionHash:",transactionHash)
}




export const CreateSellOffer = (address, Amount, tokenID) => async (dispatch) => {
    const seaport = new OpenSeaPort(web3.currentProvider, {
        networkName: Network.Mainnet
        ,apiKey:'43abf245ba3d4cbb8b5c3fcc4f0fd1db'
    })
    const tokenId = tokenID//'79630473148403579200232510153367869810807992404435473599666936436705676230657'
    const amount = Amount

    const tokenAddress = '0x495f947276749ce646f68ac8c248420045cb7b5e'//"0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656"

    const accountAddress = address.toString()



    await seaport.createSellOrder({
        asset: {
            tokenId,
            tokenAddress,
            schemaName: "ERC1155"
        },
        accountAddress, // Address of the bidder
        startAmount: amount,
        listingTime: Math.round(Date.now() / 1000 + 60 * 60 * 24)

        // referrerAddress // Address of the referrer
    })

        .then(async (transactionHash) => {




            const hash = await transactionHash.hash
            console.log("transactionHash:", hash)
            // alert("DONE:",transactionHash)
            toast.configure();
            toast.success("Offered for sell successfuly:" + hash, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                toastId: 7,
            });

            dispatch({
                type: authType.PURCHASED_NFT,
                payload: transactionHash,
            });
            // }
        })
        .catch((err) => {
            toast.configure();
            toast.error("failed with error:" + err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                toastId: 8,
            });



        });
}








export const getSpecificCollection = (slug) => (dispatch) => {
    console.log("slug:", slug)

    axios({//Testnet_openSea  Mainnet_openSea  // 0xf433b52ece73c57993d91e647c16d5ea5ffc80f3 mainet     //0xb00D4027D792A04faC532028DB1Ec6391468C138 tetsnet
        url: openSea.Mainnet_openSea() + `assets?owner=0xf433b52ece73c57993d91e647c16d5ea5ffc80f3&order_direction=desc&offset=0&limit=20&collection=${slug}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(function (resp) {
            console.log("rsp from OpenSea:", resp.data.assets);



            dispatch({
                type: authType.GET_COLLECTION_DATA,
                payload: resp.data.assets,
            });
            // }
        })
        .catch((err) => {
            //  alert("err:"+err)

        });

}


export const getAllCollections = (slug) => (dispatch) => {
    console.log("slug:", slug)
    console.log("side bar requested")
    var arr = [];
    axios({//0x95a8b209ccd61d11e7d1dce5800e6fd3b912c583    //testnet 0x8b7cde4c9b374a3fe82a353d0595c712806ef5ec
        url: openSea.Mainnet_openSea() + `collections?offset=0&limit=300&asset_owner=0x95a8b209ccd61d11e7d1dce5800e6fd3b912c583`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(function (resp) {
            // console.log("rsp from OpenSea getAllCollections:", resp.data);

            resp.data.forEach(element => {
                console.log(element.name)
                var name = element.name
                var slug = element.slug
                var obj = { name, slug }
                arr.push(obj)

            });

            dispatch({
                type: authType.GET_ALLCOLLECTION_NAME,
                payload: arr,
            });
            // }
        })
        .catch((err) => {
            alert(err)

        });

}


export const getSpecificAsset = (tokenId) => (dispatch) => {
    console.log("slug:", tokenId)

    axios({//0x495f947276749ce646f68ac8c248420045cb7b5e   //testnet 0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656
        url: openSea.Mainnet_openSea() + `asset/0x495f947276749ce646f68ac8c248420045cb7b5e/${tokenId}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(function (resp) {
            console.log("rsp from OpenSea:", resp.data);



            dispatch({
                type: authType.GET_ASSET_DATA,
                payload: resp.data,
            });
            // }

        })
        .catch((err) => {
            // alert(err)

        });
    // this.props.history.push("/nftDetail")
}













export const getCurrentUserCollection = (slug, user) => (dispatch) => {
    //alert(user)
    axios({//Testnet_openSea  Mainnet_openSea  // 0xf433b52ece73c57993d91e647c16d5ea5ffc80f3 mainet     //0xb00D4027D792A04faC532028DB1Ec6391468C138 tetsnet
        url: openSea.Mainnet_openSea() + `assets?owner=${user}&order_direction=desc&offset=0&limit=20&collection=${slug}`,
        //'https://rinkeby-api.opensea.io/api/v1/assets?owner=0x8b7CDe4C9B374a3FE82a353d0595C712806Ef5Ec&order_direction=desc&offset=0&limit=20&collection=untitled-collection-2348219',
        //openSea.Testnet_openSea()+`assets?owner=${user}&order_direction=desc&offset=0&limit=20&collection=${slug}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(function (resp) {
            console.log("rsp from OpenSea:", resp.data.assets);



            dispatch({
                type: authType.GET_COLLECTION_DATA,
                payload: resp.data.assets,
            });
            // }
        })
        .catch((err) => {
            //   alert(err)

        });

}



export const getPhysicalCollection = () => (dispatch) => {

    axios({//Testnet_openSea  Mainnet_openSea  // 0xf433b52ece73c57993d91e647c16d5ea5ffc80f3 mainet     //0xb00D4027D792A04faC532028DB1Ec6391468C138 tetsnet
        url: `http://32karateesthetix.online/wp-json/public-woo/v1/products`,
        //'https://rinkeby-api.opensea.io/api/v1/assets?owner=0x8b7CDe4C9B374a3FE82a353d0595C712806Ef5Ec&order_direction=desc&offset=0&limit=20&collection=untitled-collection-2348219',
        //openSea.Testnet_openSea()+`assets?owner=${user}&order_direction=desc&offset=0&limit=20&collection=${slug}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(function (resp) {
            console.log("rsp from OpenSea:", resp);



            dispatch({
                type: authType.GET_PHYSICAL_DATA,
                payload: resp.data,
            });
            // }
        })
        .catch((err) => {
            alert(err)

        });

}








