import { combineReducers } from "redux";

import { routerReducer } from "react-router-redux";

import { collectionStore } from "./collection/reducer";



const reducer = combineReducers({
  routing: routerReducer,
  collectionStore,


});

export default reducer;
